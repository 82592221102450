import { DefaultValues } from 'react-hook-form/dist/types/form';

import { nanoid } from 'nanoid';

import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from './calc.form';

export const awsCalcDefaultValues: DefaultValues<AwsCalcValidationSchema> = {
  companyName: '',
  emr: '',
  stateInputs: [
    {
      stateCode: '',
      ncci: null,
      payroll: '',
      rowLocalId: nanoid(),
    },
  ],
  recentMedicalClaims: '',
  recentTotalMedicalLosses: '',
  recentIndemnityClaims: '',
  recentTotalIndemnityLosses: '',
  previousMedicalClaims: '',
  previousTotalMedicalLosses: '',
  previousIndemnityClaims: '',
  previousTotalIndemnityLosses: '',
  olderMedicalClaims: '',
  olderTotalMedicalLosses: '',
  olderIndemnityClaims: '',
  olderTotalIndemnityLosses: '',
};

export const fullCalcDefaultValues: DefaultValues<FullCalcValidationSchemaType> = {
  companyName: '',
  stateInputs: [
    {
      stateCode: '',
      ncci: null,
      payroll: '',
      rowLocalId: nanoid(),
    },
  ],
  naics: null,
  emr: '',
  trir: '',
  da: '',
  // rt: '',
  safety: false,
  health: false,
  supplemental: false,
};
