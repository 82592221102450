import { createContext, useContext, FC, ReactElement, useMemo } from 'react';
import { useSessionStorage } from 'react-use';

export const VersionContext = createContext({
  version: '',
  awsVersion: '',
  setVersion: (_: string) => {},
  setAwsVersion: (_: string) => {},
});

interface Props {
  children: ReactElement;
}

export const VersionContextProvider: FC<Props> = ({ children }) => {
  const [version, setVersion] = useSessionStorage('VERSION', '');
  const [awsVersion, setAwsVersion] = useSessionStorage('AWS_VERSION', '');

  const value = useMemo(
    () => ({
      version,
      setVersion,
      awsVersion,
      setAwsVersion,
    }),
    [version, setVersion, awsVersion, setAwsVersion]
  );

  return <VersionContext.Provider value={value}>{children}</VersionContext.Provider>;
};

export const useCalcVersionContext = () => useContext(VersionContext);
