import localforage from 'localforage';

import { EAuthStatus } from '@app/auth/api/auth.type';

import { StoreSlice } from '../storeTypes';
import { AuthSlice } from './types';
import { AuthApi } from '@app/auth/api/auth.api';
import { AUTH_REF_TOKEN_KEY, AUTH_TOKEN_KEY } from '@app/auth/constant';
import { IS_AUTH_METHOD_COOKIE, IS_AUTH_METHOD_TOKEN } from '@app/environment/typed-env';
import { redirectToAuthService } from '@app/auth/auth.utils';
import { takeLatestAsync } from '@app/utils/async.utils';

export const createAuthSlice: StoreSlice<AuthSlice> = (set, get) => ({
  currentUser: null,
  authStatus: EAuthStatus.UNKNOWN,
  isSigningOut: false,

  signOut: async (payload) => {
    set({ ...get(), isSigningOut: true });

    if (IS_AUTH_METHOD_TOKEN) {
      await localforage.clear();
      set({ currentUser: null, authStatus: EAuthStatus.UNAUTHORIZED, isSigningOut: false });
    }
    if (IS_AUTH_METHOD_COOKIE) {
      if (payload?.skipCookieSignOut) {
        set({ currentUser: null, authStatus: EAuthStatus.UNAUTHORIZED, isSigningOut: false });
        return;
      }

      await AuthApi.signOut();
      set({ currentUser: null, authStatus: EAuthStatus.UNAUTHORIZED, isSigningOut: false });
    }

    redirectToAuthService(payload?.srcUrl || undefined);
  },

  setCurrentUser: async (user) => {
    const { signOut } = get();

    if (user) {
      if (IS_AUTH_METHOD_COOKIE) {
        set({ currentUser: user, authStatus: EAuthStatus.SIGNED_IN });
        return;
      }
      if (IS_AUTH_METHOD_TOKEN) {
        const { accessToken, refreshToken, ...safeUser } = user;
        if (!accessToken || !refreshToken) {
          await signOut();
          return;
        }
        await localforage.setItem(AUTH_TOKEN_KEY, accessToken);
        await localforage.setItem(AUTH_REF_TOKEN_KEY, refreshToken);
        set({ currentUser: safeUser, authStatus: EAuthStatus.SIGNED_IN });
        return;
      }
      console.error('unsupported auth method');
    } else {
      await signOut();
    }
  },

  getMe: async () => {
    const { signOut } = get();

    // TODO avoid checking token here. It is job for interceptor.
    if (IS_AUTH_METHOD_TOKEN) {
      const token = await localforage.getItem(AUTH_TOKEN_KEY);

      if (!token) {
        await signOut();
        return;
      }
    }

    const { result, staleError, error } = await takeLatestAsync(AuthApi.me(), 'authGetMe');
    if (staleError) {
      return;
    }
    if (error) {
      await signOut({ skipCookieSignOut: true });
      return;
    }
    if (!result) {
      return;
    }
    const me = result.data;
    set({ currentUser: me, authStatus: EAuthStatus.SIGNED_IN });
  },
});
