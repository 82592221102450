import { z } from 'zod';
import { EAuthMethod } from '@app/auth/constant';
import { envOverride } from './env-override';

const EnvSchema = z.object({
  REACT_APP_TITLE: z.string(),
  REACT_APP_DESCRIPTION: z.string(),
  REACT_APP_ENV_NAME: z.string(),
  REACT_APP_API_BASE_URL: z.string(),
  NODE_ENV: z.string(),
  REACT_APP_AUTH_API_URL: z.string(),
  REACT_APP_AUTH_LINK: z.string(),
  REACT_APP_USISA_LINK: z.string(),
  REACT_APP_INSURATE_LINK: z.string(),
  REACT_APP_MUI_LICENSE_KEY: z.string(),
  REACT_APP_AUTH_METHOD: z.nativeEnum(EAuthMethod),
  REACT_APP_SUPPORT_DARK_THEME: z
    .string()
    .optional()
    .default('false')
    .transform((value) => value === 'true'),
  REACT_APP_EXCLUDE_ROUTES_LIST: z.string().optional().default(''),
  REACT_APP_EXCLUDE_FEATURES_LIST: z.string().optional().default(''),
});

export const typedEnv = EnvSchema.parse({
  ...process.env,
  ...(process.env.REACT_APP_ENV_NAME !== 'production' && envOverride.state),
});

export const IS_AUTH_METHOD_COOKIE = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.COOKIE;
export const IS_AUTH_METHOD_TOKEN = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.TOKEN;

export const IS_PROD_ENV = typedEnv.REACT_APP_ENV_NAME === 'production';
export const IS_NOT_PROD_ENV = !IS_PROD_ENV;

enum ERouteMenuItems {
  STI = 'STI',
  CLAIMS = 'CLAIMS',
  CLAIMS_INFO = 'CLAIMS_INFO',
  LOSS_RATIO = 'LOSS_RATIO',
  UNDERWRITING = 'UNDERWRITING',
  ADMIN = 'ADMIN',
}

enum EFeatureItems {
  SUBMISSION_TABS = 'SUBMISSION_TABS',
}

const EXCLUDE_ROUTES_LIST = typedEnv.REACT_APP_EXCLUDE_ROUTES_LIST.split(',');
const EXCLUDE_FEATURES_LIST = typedEnv.REACT_APP_EXCLUDE_FEATURES_LIST.split(',');

export const HAS_ACCESS_TO_STI = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.STI);
export const HAS_ACCESS_TO_CLAIMS = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.CLAIMS);
export const HAS_ACCESS_TO_CLAIMS_INFO = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.CLAIMS_INFO);
export const HAS_ACCESS_TO_LOSS_RATIO = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.LOSS_RATIO);
export const HAS_ACCESS_TO_UNDERWRITING = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.UNDERWRITING);
export const HAS_ACCESS_TO_ADMIN = !EXCLUDE_ROUTES_LIST.includes(ERouteMenuItems.ADMIN);

export const HAS_ACCESS_TO_SUBMISSION_TABS = !EXCLUDE_FEATURES_LIST.includes(EFeatureItems.SUBMISSION_TABS);
