/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  message?: string;
}

export interface ErrorResponse {
  /** @format int64 */
  timestamp?: number;
  /** @format int32 */
  status?: number;
  errors?: Error[];
}

export interface CalcErrorResponse {
  /** @format int64 */
  timestamp?: number;
  /** @format int32 */
  status?: number;
  errors?: Error[];
  responseDetail?: StiResponseDetailErrors[];
}

export interface StiResponseDetailErrors {
  code?: string;
  message?: string;
}

export interface UpdateUserInDto {
  firstName: string;
  lastName: string;
  company: string;
  userPoolGroup?: string[];
}

export enum EUserConfirmationStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  RESET_REQUIRED = 'RESET_REQUIRED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
}

export enum EUserStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export interface UserOutDto {
  loginName: string;
  username: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  emailVerified: boolean;
  groupList: string[];
  userConfirmationStatus: EUserConfirmationStatus;
  userStatus: EUserStatus;
  /** @format date-time */
  userCreateDate: string;
  /** @format date-time */
  userLastModifiedDate: string;
  enabled: boolean;
  poolName: string;
}

export enum EConfigType {
  CLAIMS_TABLE = 'CLAIMS_TABLE',
  LOSS_RATIO_SUMMARY_TABLE = 'LOSS_RATIO_SUMMARY_TABLE',
  LOSS_RATIO_DETAILED_VIEW_TABLE = 'LOSS_RATIO_DETAILED_VIEW_TABLE',
  SUBMISSIONS_TABLE = 'SUBMISSIONS_TABLE',
}

export type JsonNode = object;

export interface UserConfigDto {
  /** @format int64 */
  id?: number;
  type: EConfigType;
  configs: JsonNode;
}

export enum ESubmissionFileStatus {
  INITIAL = 'INITIAL',
  PROCESSING = 'PROCESSING',
  POLLING_FOR_RESULT = 'POLLING_FOR_RESULT',
  DOWNLOADING_RESPONSE = 'DOWNLOADING_RESPONSE',
  CHECKING_RESPONSE = 'CHECKING_RESPONSE',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  UPDATING_RESULTS = 'UPDATING_RESULTS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum ESubmissionStatus {
  DRAFT = 'DRAFT',
  EXTRACTING = 'EXTRACTING',
  EXTRACTING_PARTIAL = 'EXTRACTING_PARTIAL',
  EXTRACTING_ERROR = 'EXTRACTING_ERROR',
  EXTRACTING_COMPLETED = 'EXTRACTING_COMPLETED',
}

export type SubmissionFileCompactOutDto = {
  /** @format int64 */
  id: number;
  filename: string;
  status: ESubmissionFileStatus;
  errorMessage?: string | null;
  /** @format date-time */
  createdDate: string;
};

export interface SubmissionNamedInsuredOutDto {
  /** @format int64 */
  id: number;
  name: string;
  fein?: string | null;
}

export interface SubmissionOutDto {
  /** @format int64 */
  id: number;
  submissionNamedInsured: SubmissionNamedInsuredOutDto;
  /** @format date */
  effectiveDate: string;
  /** @format date-time */
  createdDate: string;
  creator: string;
  assignee?: string | null;
  assigneeName?: string | null;
  status: ESubmissionStatus;
  fileOutAccordApplicationDtos?: SubmissionFileCompactOutDto[] | null;
  fileOutEmrDtos?: SubmissionFileCompactOutDto[] | null;
  fileOutLossRunsDtos?: SubmissionFileCompactOutDto[] | null;
  /** @format int64 */
  stiOutcomeId?: number | null;
  isVerified: boolean;
}

export interface SubmissionPutDto {
  assignee?: string;
  verified: boolean;
}

export interface SubmissionStiFullPrefillInDto {
  naics?: string;
  /** @format double */
  trir?: number;
  /** @format double */
  dart?: number;
  /** @format double */
  emr?: number;
  safetyPrograms?: boolean;
  healthCoverage?: boolean;
  supplementalCoverage?: boolean;
  payrollEntries: SubmissionStiPayrollEntryInDto[];
}

export interface SubmissionStiPayrollEntryInDto {
  state?: string;
  ncci?: string;
  /** @format double */
  amount?: number;
}

export interface SubmissionStiFullPrefillOutDto {
  naics?: string | null;
  /** @format double */
  trir?: number | null;
  /** @format double */
  dart?: number | null;
  /** @format double */
  emr?: number | null;
  safetyPrograms?: boolean | null;
  healthCoverage?: boolean | null;
  supplementalCoverage?: boolean | null;
  payrollEntries: SubmissionStiPayrollEntryOutDto[];
}

export interface SubmissionStiPayrollEntryOutDto {
  state?: string | null;
  ncci?: string | null;
  /** @format double */
  amount?: number | null;
}

export interface SubmissionStiAwsPrefillInDto {
  /** @format double */
  emr?: number;
  /** @format int32 */
  yearsInBusiness?: number;
  recentYear: SubmissionStiYearPrefillInDto;
  previousYear?: SubmissionStiYearPrefillInDto;
  twoYearsAgo?: SubmissionStiYearPrefillInDto;
  payrollEntries: SubmissionStiPayrollEntryInDto[];
}

export interface SubmissionStiYearPrefillInDto {
  /** @format int32 */
  medicalClaims?: number;
  /** @format double */
  totalMedicalLosses?: number;
  /** @format int32 */
  indemnityClaims?: number;
  /** @format double */
  totalIndemnityLosses?: number;
}

export interface SubmissionStiAwsPrefillOutDto {
  /** @format double */
  emr?: number | null;
  /** @format int32 */
  yearsInBusiness?: number | null;
  recentYear: SubmissionStiYearPrefillOutDto;
  previousYear?: SubmissionStiYearPrefillOutDto;
  twoYearsAgo?: SubmissionStiYearPrefillOutDto;
  payrollEntries: SubmissionStiPayrollEntryOutDto[];
}

export type SubmissionStiYearPrefillOutDto = {
  /** @format int32 */
  medicalClaims?: number | null;
  /** @format double */
  totalMedicalLosses?: number | null;
  /** @format int32 */
  indemnityClaims?: number | null;
  /** @format double */
  totalIndemnityLosses?: number | null;
};

export interface SignUpStepOneInDto {
  /** @example "dberchiyan@osdb.io" */
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  userPoolGroup?: string[];
}

export interface SubmissionInDto {
  /** @format int64 */
  namedInsuredId: number;
  /**
   * @format date
   * @example "2000-10-31"
   */
  effectiveDate: string;
  creator: string;
  assignee?: string;
}

export enum ESubmissionFileType {
  ACCORD = 'ACCORD',
  EMR = 'EMR',
  LOSS_RUNS = 'LOSS_RUNS',
}

export interface SubmissionNamedInsuredInDto {
  name: string;
  /**
   * @pattern [0-9]{2}-[0-9]{7}
   * @example "34-6789345"
   */
  fein?: string;
}

export interface CalcInDto {
  /** @example "Company 1" */
  companyName?: string;
  years: YearDto[];
  /** @example ["webp_without_rt"] */
  charts?: string[];
}

export interface StateInput {
  /** @example "Illinois" */
  stateCode: string;
  /** @example "5535" */
  ncci: string;
  /**
   * @format double
   * @example 2710100
   */
  payroll: number;
}

export interface YearDto {
  /** @example "2022" */
  year: string;
  /** @example "484230" */
  naicsCode: string;
  /**
   * @format double
   * @example 0.3
   */
  emr: number;
  /**
   * @format double
   * @example 0
   */
  trir: number;
  /**
   * @format double
   * @example 0
   */
  da: number;
  /**
   * @format double
   * @example 0
   */
  rt: number;
  /** @example false */
  safety: boolean;
  /** @example false */
  health: boolean;
  /** @example false */
  supplemental: boolean;
  stateInputs: StateInput[];
}

export interface CalcFullOutDto {
  isSuccessful: boolean;
  /** @format int64 */
  responseId?: number;
  responseType: EOutcomeType;
  version?: StiResponseVersion;
  warnings?: StiResponseDetailErrors[];
  errors?: StiResponseDetailErrors[];
  payload: JsonNode;
  data?: CalcOutDataDto;
  /** @format double */
  originalCrDb?: number;
  originalStates?: JsonNode;
}

export interface CalcOutDataDto {
  companyName: string;
  /** @format double */
  sti?: number;
  stiGrade: EStiGrade;
  confidence?: string;
  /** @format double */
  stpPremium?: number;
  /** @format double */
  totalExpClaims?: number;
  /** @format double */
  finalPremium?: number;
  /** @format double */
  coEmr?: number;
  /** @format double */
  coTrir?: number;
  /** @format double */
  coDa?: number;
  /** @format double */
  coRt?: number;
  /** @format double */
  expectedEmr?: number;
  /** @format double */
  expectedTrir?: number;
  /** @format double */
  expectedRt?: number;
  /** @format double */
  expectedDa?: number;
  states?: CalcStateOutDto[];
  incidentStats?: IncidentOutDto[];
  chartData?: Record<string, string>[];
  /** @format double */
  assocDues?: number;
  comparisons?: Comparisons;
}

export interface CalcStateOutDto {
  code?: string;
  classCode?: string;
  /** @format double */
  payroll?: number;
  /** @format double */
  safeTierPremium?: number;
  stiCrDB?: string;
  /** @format double */
  expectedClaims?: number;
  /** @format double */
  finalPremium?: number;
  /** @format double */
  finalCrDB?: number;
  /** @format double */
  netFinalRate?: number;
  filing?: string;
  formattedStiCrDb?: string;
}

export interface Comparison {
  emr?: string;
  trir?: string;
  dart?: string;
  medicalClaims?: string;
  indemnityClaims?: string;
  totalClaims?: string;
  medicalLosses?: string;
  indemnityLossed?: string;
  totalLosses?: string;
}

export interface Comparisons {
  company?: Comparison;
  expected?: Comparison;
  states?: StateComparison[];
  stateNcci?: StateNcciComparison[];
}

export enum EImpact {
  NEGATIVE_IMPACT = 'NEGATIVE_IMPACT',
  POSITIVE_IMPACT = 'POSITIVE_IMPACT',
  NEUTRAL_IMPACT = 'NEUTRAL_IMPACT',
  SAFE_TIER_SCORE = 'SAFE_TIER_SCORE',
  OTHER = 'OTHER',
}

export enum EOutcomeType {
  MULTI_CALC = 'MULTI_CALC',
  FULL_CALC = 'FULL_CALC',
  AWS_CALC = 'AWS_CALC',
}

export enum EStiGrade {
  VERY_WEAK = 'VERY_WEAK',
  WEAK = 'WEAK',
  BORDERLINE = 'BORDERLINE',
  AVERAGE = 'AVERAGE',
  GOOD = 'GOOD',
  STRONG = 'STRONG',
  VERY_STRONG = 'VERY_STRONG',
  OTHER = 'OTHER',
}

export interface IncidentOutDto {
  impact?: EImpact;
  text?: string;
}

export interface StateComparison {
  state?: string;
  /** @format double */
  impactWeight?: number;
  blsSourceUsed?: string;
  perfectMatch?: string;
  naicsCodeUsed?: string;
  phraseology?: string;
  /** @format int32 */
  impactWeightPercent?: number;
}

export interface StateNcciComparison {
  stateClassCode?: string;
  /** @format double */
  impactWeight?: number;
  ncciReportedPolicies?: string;
  phraseology?: string;
  /** @format int32 */
  impactWeightPercent?: number;
  /** @format double */
  avgNcciPayroll?: number;
  /** @format double */
  ncciAvgMedIncurred?: number;
  /** @format double */
  avgNcciIndemIncurred?: number;
  /** @format double */
  ncciAvgTotalIncurred?: number;
  /** @format double */
  companyPayroll?: number;
}

export interface StiResponseVersion {
  reqTs?: string;
  model?: string;
}

export enum EGroupLossRatioBy {
  POLICY_NUMBER = 'POLICY_NUMBER',
  BINDING_MONTH = 'BINDING_MONTH',
  NCCI = 'NCCI',
  STATE = 'STATE',
  AGENCY = 'AGENCY',
}

export interface LossRatioFilter {
  groupBy: EGroupLossRatioBy;
  /** @example "03/21" */
  bindingMonthStart?: string;
  /** @example "03/22" */
  bindingMonthEnd?: string;
  /** @example "08/20/2021" */
  effectiveDateStart?: string;
  /** @example "08/21/2021" */
  effectiveDateEnd?: string;
  ncci?: number[];
  agencies?: string[];
  states?: string[];
}

export interface LossRatioSummaryTotalOutDto {
  /** @format int32 */
  totalRecords?: number;
  /** @format float */
  writtenPremium?: number;
  /** @format int32 */
  daysIntoPolicy?: number;
  /** @format float */
  earnedPremium?: number;
  /** @format float */
  lossRatio?: number;
  /** @format float */
  netIncurred?: number;
  /** @format float */
  totalReimbursement?: number;
  /** @format float */
  totalIncurred?: number;
  /** @format float */
  totalMedical?: number;
  /** @format float */
  totalIndemnity?: number;
  /** @format float */
  totalExpense?: number;
}

export interface LossRatioPageResponseLossRatioSummaryOutDto {
  lossRatioFilter?: LossRatioFilter;
  result: LossRatioSummaryOutDto[];
}

export interface LossRatioSummaryOutDto {
  groupingBy?: string;
  /** @format float */
  writtenPremium?: number;
  /** @format int32 */
  daysIntoPolicy?: number;
  /** @format float */
  earnedPremium?: number;
  /** @format float */
  lossRatio?: number;
  /** @format float */
  netIncurred?: number;
  /** @format float */
  totalReimbursement?: number;
  /** @format float */
  totalIncurred?: number;
  /** @format float */
  totalMedical?: number;
  /** @format float */
  totalIndemnity?: number;
  /** @format float */
  totalExpense?: number;
}

export interface FilterConfigOutDto {
  id: string;
}

export interface LossRatioDetailTotalOutDto {
  /** @format int32 */
  totalRecords?: number;
  /** @format float */
  writtenPremium?: number;
  /** @format int32 */
  daysIntoPolicy?: number;
  /** @format float */
  earnedPremium?: number;
  /** @format float */
  lossRatio?: number;
  /** @format float */
  netIncurred?: number;
  /** @format float */
  totalReimbursement?: number;
  /** @format float */
  totalIncurred?: number;
  /** @format float */
  totalMedical?: number;
  /** @format float */
  totalIndemnity?: number;
  /** @format float */
  totalExpense?: number;
}

export interface LossRatioDetailOutDto {
  groupingBy?: string;
  policyNumber?: string;
  insuredName?: string;
  /** @format date */
  effectiveDate?: string;
  /** @format date */
  cancelDate?: string;
  /** @format float */
  writtenPremium?: number;
  /** @format int32 */
  daysIntoPolicy?: number;
  /** @format float */
  earnedPremium?: number;
  /** @format float */
  lossRatio?: number;
  /** @format float */
  netIncurred?: number;
  /** @format float */
  totalReimbursement?: number;
  /** @format float */
  totalIncurred?: number;
  /** @format float */
  totalMedical?: number;
  /** @format float */
  totalIndemnity?: number;
  /** @format float */
  totalExpense?: number;
}

export interface LossRatioPageResponseLossRatioDetailOutDto {
  lossRatioFilter?: LossRatioFilter;
  result: LossRatioDetailOutDto[];
}

export interface LossRatioChartOutDto {
  rowName: string;
  /** @format int32 */
  rowValue: number;
}

export interface LossRatioPageResponseLossRatioChartOutDto {
  lossRatioFilter?: LossRatioFilter;
  result: LossRatioChartOutDto[];
}

export interface CalcFullViewInDto {
  /** @example "Company 1" */
  companyName?: string;
  /** @example "484230" */
  naicsCode: string;
  /**
   * @format double
   * @example 0.3
   */
  emr: number;
  /**
   * @format double
   * @example 0
   */
  rt: number;
  /**
   * @format double
   * @example 0
   */
  trir: number;
  /**
   * @format double
   * @example 0
   */
  da: number;
  /** @example false */
  safety: boolean;
  /** @example false */
  health: boolean;
  /** @example false */
  supplemental: boolean;
  /** @example ["webp_without_rt"] */
  charts?: string[];
  stateInputs: StateInput[];
  originalStates?: JsonNode;
}

export interface ClaimFilter {
  policyNumber?: string[];
  claimNumber?: string[];
  claimant?: string[];
  claimStatus?: string[];
  claimType?: string[];
  nameInsured?: string[];
  /** @format date */
  dateOfLossFrom?: string;
  /** @format date */
  dateOfLossTo?: string;
  /** @format float */
  lossPaidFrom?: number;
  /** @format float */
  lossPaidTo?: number;
  /** @format float */
  lossReservesFrom?: number;
  /** @format float */
  lossReservesTo?: number;
  /** @format float */
  totalIncurredFrom?: number;
  /** @format float */
  totalIncurredTo?: number;
  /** @format date */
  dateOfHireFrom?: string;
  /** @format date */
  dateOfHireTo?: string;
  /** @format date */
  dateOfBirthFrom?: string;
  /** @format date */
  dateOfBirthTo?: string;
  /** @format date */
  reopenedDateFrom?: string;
  /** @format date */
  reopenedDateTo?: string;
  /** @format date */
  dateClosedFrom?: string;
  /** @format date */
  dateClosedTo?: string;
  /** @format date */
  claimCreatedDateFrom?: string;
  /** @format date */
  claimCreatedDateTo?: string;
  /** @format int32 */
  claimSubmittedLagFrom?: number;
  /** @format int32 */
  claimSubmittedLagTo?: number;
  /** @format int32 */
  totalDaysLostFrom?: number;
  /** @format int32 */
  totalDaysLostTo?: number;
  /** @format date */
  dateOfDeathFrom?: string;
  /** @format date */
  dateOfDeathTo?: string;
  sex?: string[];
  agency?: string[];
  claimSubmittedName?: string[];
  lossType?: string[];
  lossTypeGroup?: string[];
  lostTimeIndicator?: string[];
  locationNumber?: string[];
  locationState?: string[];
  locationZip?: string[];
  accidentState?: string[];
  stateOfJurisdiction?: string[];
  diabetesFlag?: string[];
  obesityFlag?: string[];
  highCostPrediction?: string[];
  claimRiskLevel?: string[];
  subrogationStatus?: string[];
  litigation?: string[];
  causeCode?: string[];
  causeCodeGroup?: string[];
  bodyPartDetail?: string[];
  bodyPartGroup?: string[];
  accidentDescription?: string[];
  industryCode?: string[];
  jobClass?: string[];
  jobCode?: string[];
  ncciInjuryType?: string[];
  policyPeriod?: string[];
  locationAddressLine1?: string[];
  locationCity?: string[];
  locationCounty?: string[];
  location2?: string[];
  location2AddressLine1?: string[];
  location2City?: string[];
  location2State?: string[];
  adjuster?: string[];
  adjusterEmail?: string[];
  adjusterPhone?: string[];
  returnedToWork?: string[];
  riskLoweringFactor1?: string[];
  riskLoweringFactor2?: string[];
  riskLoweringFactor3?: string[];
  riskLoweringFactor4?: string[];
  riskLoweringFactor5?: string[];
  riskRaisingFactor1?: string[];
  riskRaisingFactor2?: string[];
  riskRaisingFactor3?: string[];
  riskRaisingFactor4?: string[];
  suitFiled?: string[];
  dayOfWeek?: string[];
  copdFlag?: string[];
  coronaryArteryDiseaseFlag?: string[];
  osteoarthritisFlag?: string[];
  otherCardiacDisordersFlag?: string[];
  carrier?: string[];
  substanceDependenceFlag?: string[];
  claimantEmail?: string[];
  claimantPhone?: string[];
  claimantWorkEmail?: string[];
  claimantWorkPhone?: string[];
  claimantState?: string[];
  claimantCity?: string[];
  mobilePhone?: string[];
  handlingOffice?: string[];
  claimDenied?: string[];
  socialSecurityNumber?: string[];
  jobTitleCarrier?: string[];
  supervisor?: string[];
  employmentType?: string[];
  claimantAttorneyRepresented?: string[];
  groupCode1?: string[];
  claimSubmittedUserId?: string[];
  claimantDied?: string[];
  initialTreatment?: string[];
  physicianHospitalName?: string[];
  location2Number?: string[];
  location2Zip?: string[];
  claimantZip?: string[];
  /** @format int32 */
  claimantAgeFrom?: number;
  /** @format int32 */
  claimantAgeTo?: number;
  /** @format date */
  dateSuitFiledFrom?: string;
  /** @format date */
  dateSuitFiledTo?: string;
  /** @format date */
  dateOfMaxImprovementFrom?: string;
  /** @format date */
  dateOfMaxImprovementTo?: string;
  /** @format date */
  settlementDateFrom?: string;
  /** @format date */
  settlementDateTo?: string;
  /** @format date */
  terminationDateFrom?: string;
  /** @format date */
  terminationDateTo?: string;
  /** @format date */
  predictionDateFrom?: string;
  /** @format date */
  predictionDateTo?: string;
  /** @format date */
  policyPeriodEndDateFrom?: string;
  /** @format date */
  policyPeriodEndDateTo?: string;
  /** @format date */
  policyPeriodStartDateFrom?: string;
  /** @format date */
  policyPeriodStartDateTo?: string;
  /** @format date */
  claimantReportDateFrom?: string;
  /** @format date */
  claimantReportDateTo?: string;
  /** @format date */
  claimDeniedDateFrom?: string;
  /** @format date */
  claimDeniedDateTo?: string;
  /** @format date */
  claimSubmittedDateFrom?: string;
  /** @format date */
  claimSubmittedDateTo?: string;
  /** @format date */
  dateLastWorkedFrom?: string;
  /** @format date */
  dateLastWorkedTo?: string;
  /** @format date */
  firstDayOfDisabilityFrom?: string;
  /** @format date */
  firstDayOfDisabilityTo?: string;
  /** @format date */
  fullDutyDateFrom?: string;
  /** @format date */
  fullDutyDateTo?: string;
  /** @format date */
  returnToWorkDateFrom?: string;
  /** @format date */
  returnToWorkDateTo?: string;
  /** @format date */
  lastPaymentDateFrom?: string;
  /** @format date */
  lastPaymentDateTo?: string;
  /** @format float */
  percentImpairmentFrom?: number;
  /** @format float */
  percentImpairmentTo?: number;
  /** @format float */
  ttdRateFrom?: number;
  /** @format float */
  ttdRateTo?: number;
  /** @format float */
  ppdRateFrom?: number;
  /** @format float */
  ppdRateTo?: number;
  /** @format float */
  electroDiagnosticPredictionFrom?: number;
  /** @format float */
  electroDiagnosticPredictionTo?: number;
  /** @format float */
  imagingPredictionFrom?: number;
  /** @format float */
  imagingPredictionTo?: number;
  /** @format float */
  opioidPredictionFrom?: number;
  /** @format float */
  opioidPredictionTo?: number;
  /** @format float */
  painManagementPredictionFrom?: number;
  /** @format float */
  painManagementPredictionTo?: number;
  /** @format float */
  physTherapyChiroPredictionFrom?: number;
  /** @format float */
  physTherapyChiroPredictionTo?: number;
  /** @format float */
  surgeryPredictionFrom?: number;
  /** @format float */
  surgeryPredictionTo?: number;
  /** @format int32 */
  medicalPaidCoinFrom?: number;
  /** @format int32 */
  medicalPaidCoinTo?: number;
  /** @format int32 */
  indemnityTotalPaidCoinFrom?: number;
  /** @format int32 */
  indemnityTotalPaidCoinTo?: number;
  /** @format int32 */
  indemnityTotalIncurredCoinFrom?: number;
  /** @format int32 */
  indemnityTotalIncurredCoinTo?: number;
  /** @format int32 */
  expensePaidCoinFrom?: number;
  /** @format int32 */
  expensePaidCoinTo?: number;
  /** @format int32 */
  expenseTotalIncurredCoinFrom?: number;
  /** @format int32 */
  expenseTotalIncurredCoinTo?: number;
  /** @format int32 */
  totalPaidCoinFrom?: number;
  /** @format int32 */
  totalPaidCoinTo?: number;
  /** @format int32 */
  totalIncurredCoinFrom?: number;
  /** @format int32 */
  totalIncurredCoinTo?: number;
  /** @format int32 */
  lossPaidCoinFrom?: number;
  /** @format int32 */
  lossPaidCoinTo?: number;
  /** @format int32 */
  lossReservesCoinFrom?: number;
  /** @format int32 */
  lossReservesCoinTo?: number;
  /** @format int32 */
  medicalReservesCoinFrom?: number;
  /** @format int32 */
  medicalReservesCoinTo?: number;
  /** @format int32 */
  indemnityTotalReservesCoinFrom?: number;
  /** @format int32 */
  indemnityTotalReservesCoinTo?: number;
  /** @format int32 */
  expenseReservesCoinFrom?: number;
  /** @format int32 */
  expenseReservesCoinTo?: number;
  /** @format int32 */
  outstandingReservesCoinFrom?: number;
  /** @format int32 */
  outstandingReservesCoinTo?: number;
  /** @format int32 */
  medicalTotalIncurredCoinFrom?: number;
  /** @format int32 */
  medicalTotalIncurredCoinTo?: number;
  /** @format int32 */
  totalIncurredPredictionCoinFrom?: number;
  /** @format int32 */
  totalIncurredPredictionCoinTo?: number;
  /** @format int32 */
  netIncurredCoinFrom?: number;
  /** @format int32 */
  netIncurredCoinTo?: number;
  /** @format int32 */
  weeklyWageCoinFrom?: number;
  /** @format int32 */
  weeklyWageCoinTo?: number;
  /** @format int32 */
  outstandingReservesLastClosureCoinFrom?: number;
  /** @format int32 */
  outstandingReservesLastClosureCoinTo?: number;
  /** @format int32 */
  totalReimbursementCoinFrom?: number;
  /** @format int32 */
  totalReimbursementCoinTo?: number;
  /** @format int32 */
  sirDeductibleCoinFrom?: number;
  /** @format int32 */
  sirDeductibleCoinTo?: number;
  /** @format int32 */
  settlementAmountCoinFrom?: number;
  /** @format int32 */
  settlementAmountCoinTo?: number;
  /** @format int32 */
  subrogationEstimatedAmountCoinFrom?: number;
  /** @format int32 */
  subrogationEstimatedAmountCoinTo?: number;
}

export interface ClaimOutDto {
  /** @format int64 */
  id: number;
  claimNumber: string;
  claimant?: string;
  policyNumber?: string;
  location?: string;
  locationState?: string;
  accidentState?: string;
  stateOfJurisdiction?: string;
  /** @format date-time */
  claimCreatedDate?: string;
  claimStatus?: string;
  /** @format date-time */
  dateClosed?: string;
  /** @format date-time */
  reopenedDate?: string;
  /** @format int32 */
  totalDaysLost?: number;
  lostTimeIndicator?: string;
  /** @format int32 */
  medicalPaidCoin: number;
  /** @format int32 */
  indemnityTotalPaidCoin: number;
  /** @format int32 */
  indemnityTotalIncurredCoin: number;
  /** @format int32 */
  expensePaidCoin: number;
  /** @format int32 */
  expenseTotalIncurredCoin: number;
  /** @format int32 */
  totalPaidCoin: number;
  /** @format int32 */
  totalIncurredCoin: number;
  claimType?: string;
  /** @format date-time */
  dateOfDeath?: string;
  causeCode?: string;
  causeCodeGroup?: string;
  lossType?: string;
  lossTypeGroup?: string;
  /** @format int32 */
  lossPaidCoin: number;
  /** @format int32 */
  lossReservesCoin: number;
  /** @format date-time */
  dateOfBirth?: string;
  sex?: string;
  agency?: string;
  policyPeriod?: string;
  locationNumber?: string;
  locationAddressLine1?: string;
  locationCity?: string;
  locationCounty?: string;
  /** @format int32 */
  locationZip?: number;
  location2?: string;
  /** @format int32 */
  location2Number?: number;
  location2AddressLine1?: string;
  location2City?: string;
  location2State?: string;
  /** @format int32 */
  location2Zip?: number;
  /** @format date-time */
  dateOfHire?: string;
  /** @format int32 */
  claimSubmittedLag?: number;
  /** @format date-time */
  dateOfLoss?: string;
  claimSubmittedName?: string;
  ncciInjuryType?: string;
  jobClass?: string;
  /** @format int32 */
  jobCode?: number;
  /** @format int32 */
  industryCode?: number;
  accidentDescription?: string;
  accidentFullDescription?: string;
  bodyPartGroup?: string;
  /** @format int32 */
  medicalReservesCoin: number;
  /** @format int32 */
  indemnityTotalReservesCoin: number;
  /** @format int32 */
  expenseReservesCoin: number;
  /** @format int32 */
  outstandingReservesCoin: number;
  /** @format int32 */
  medicalTotalIncurredCoin: number;
  bodyPartDetail?: string;
  litigation?: string;
  subrogationStatus?: string;
  claimRiskLevel?: string;
  highCostPrediction?: string;
  obesityFlag?: string;
  diabetesFlag?: string;
  adjuster?: string;
  adjusterEmail?: string;
  adjusterPhone?: string;
  returnedToWork?: string;
  riskLoweringFactor1?: string;
  riskLoweringFactor2?: string;
  riskLoweringFactor3?: string;
  riskLoweringFactor4?: string;
  riskLoweringFactor5?: string;
  riskRaisingFactor1?: string;
  riskRaisingFactor2?: string;
  riskRaisingFactor3?: string;
  riskRaisingFactor4?: string;
  /** @format int32 */
  totalIncurredPredictionCoin: number;
  /** @format int32 */
  netIncurredCoin: number;
  /** @format int32 */
  outstandingReservesLastClosureCoin: number;
  /** @format date-time */
  dateSuitFiled?: string;
  suitFiled?: string;
  /** @format date-time */
  dateOfMaxImprovement?: string;
  dayOfWeek?: string;
  copdFlag?: string;
  coronaryArteryDiseaseFlag?: string;
  osteoarthritisFlag?: string;
  otherCardiacDisordersFlag?: string;
  carrier?: string;
  substanceDependenceFlag?: string;
  claimantEmail?: string;
  claimantPhone?: string;
  claimantWorkEmail?: string;
  claimantWorkPhone?: string;
  claimantState?: string;
  claimantCity?: string;
  mobilePhone?: string;
  handlingOffice?: string;
  claimDenied?: string;
  socialSecurityNumber?: string;
  jobTitleCarrier?: string;
  supervisor?: string;
  employmentType?: string;
  claimantAttorneyRepresented?: string;
  groupCode1?: string;
  claimSubmittedUserId?: string;
  claimantDied?: string;
  initialTreatment?: string;
  physicianHospitalName?: string;
  /** @format float */
  percentImpairment?: number;
  /** @format int32 */
  totalReimbursementCoin: number;
  /** @format int32 */
  weeklyWageCoin: number;
  /** @format float */
  ttdRate?: number;
  /** @format float */
  ppdRate?: number;
  /** @format float */
  electroDiagnosticPrediction?: number;
  /** @format float */
  imagingPrediction?: number;
  /** @format float */
  opioidPrediction?: number;
  /** @format float */
  painManagementPrediction?: number;
  /** @format float */
  physTherapyChiroPrediction?: number;
  /** @format float */
  surgeryPrediction?: number;
  /** @format date-time */
  settlementDate?: string;
  /** @format int32 */
  claimantZip?: number;
  /** @format date-time */
  terminationDate?: string;
  /** @format int32 */
  sirDeductibleCoin: number;
  /** @format int32 */
  settlementAmountCoin: number;
  /** @format int32 */
  subrogationEstimatedAmountCoin: number;
  /** @format int32 */
  claimantAge?: number;
  /** @format date-time */
  predictionDate?: string;
  /** @format date-time */
  policyPeriodEndDate?: string;
  /** @format date-time */
  policyPeriodStartDate?: string;
  /** @format date-time */
  claimantReportDate?: string;
  /** @format date-time */
  claimDeniedDate?: string;
  /** @format date-time */
  claimSubmittedDate?: string;
  /** @format date-time */
  dateLastWorked?: string;
  /** @format date-time */
  firstDayOfDisability?: string;
  /** @format date-time */
  fullDutyDate?: string;
  /** @format date-time */
  returnToWorkDate?: string;
  /** @format date-time */
  lastPaymentDate?: string;
}

export interface ClaimPageResponse {
  summary: Summary;
  claimFilter?: ClaimFilter;
  result: ClaimOutDto[];
  /** @format int64 */
  total: number;
}

export interface Summary {
  /** @format int32 */
  medicalPaidCoin: number;
  /** @format int32 */
  indemnityTotalPaidCoin: number;
  /** @format int32 */
  expensePaidCoin: number;
  /** @format int32 */
  expenseTotalIncurredCoin: number;
  /** @format int32 */
  indemnityTotalIncurredCoin: number;
  /** @format int32 */
  totalIncurredCoin: number;
  /** @format int32 */
  medicalReservesCoin: number;
  /** @format int32 */
  indemnityTotalReservesCoin: number;
  /** @format int32 */
  expenseReservesCoin: number;
  /** @format int32 */
  outstandingReservesCoin: number;
  /** @format int32 */
  totalPaidCoin: number;
  /** @format int32 */
  lossPaidCoin: number;
  /** @format int32 */
  lossReservesCoin: number;
  /** @format int32 */
  medicalTotalIncurredCoin: number;
  /** @format int32 */
  totalIncurredPredictionCoin: number;
  /** @format int32 */
  netIncurredCoin: number;
  /** @format int32 */
  outstandingReservesLastClosureCoin: number;
  /** @format int32 */
  sirDeductibleCoin: number;
  /** @format int32 */
  settlementAmountCoin: number;
  /** @format int32 */
  subrogationEstimatedAmountCoin: number;
  /** @format int32 */
  totalReimbursementCoin: number;
  /** @format int32 */
  weeklyWageCoin: number;
}

export enum EFieldType {
  POLICY_NUMBER = 'POLICY_NUMBER',
  CLAIM_NUMBER = 'CLAIM_NUMBER',
  CLAIM_STATUS = 'CLAIM_STATUS',
  CLAIM_TYPE = 'CLAIM_TYPE',
  NAME_INSURED = 'NAME_INSURED',
  SEX = 'SEX',
  AGENCY = 'AGENCY',
  CLAIM_SUBMITTED_NAME = 'CLAIM_SUBMITTED_NAME',
  LOSS_TYPE = 'LOSS_TYPE',
  LOSS_TYPE_GROUP = 'LOSS_TYPE_GROUP',
  LOST_TIME_INDICATOR = 'LOST_TIME_INDICATOR',
  LOCATION_NUMBER = 'LOCATION_NUMBER',
  LOCATION_STATE = 'LOCATION_STATE',
  LOCATION_ZIP = 'LOCATION_ZIP',
  ACCIDENT_STATE = 'ACCIDENT_STATE',
  STATE_OF_JURISDICTION = 'STATE_OF_JURISDICTION',
  DIABETES_FLAG = 'DIABETES_FLAG',
  OBESITY_FLAG = 'OBESITY_FLAG',
  HIGH_COST_PREDICTION = 'HIGH_COST_PREDICTION',
  CLAIM_RISK_LEVEL = 'CLAIM_RISK_LEVEL',
  SUBROGATION_STATUS = 'SUBROGATION_STATUS',
  LITIGATION = 'LITIGATION',
  CAUSE_CODE = 'CAUSE_CODE',
  CAUSE_CODE_GROUP = 'CAUSE_CODE_GROUP',
  BODY_PART_DETAIL = 'BODY_PART_DETAIL',
  BODY_PART_GROUP = 'BODY_PART_GROUP',
  ACCIDENT_DESCRIPTION = 'ACCIDENT_DESCRIPTION',
  INDUSTRY_CODE = 'INDUSTRY_CODE',
  JOB_CLASS = 'JOB_CLASS',
  JOB_CODE = 'JOB_CODE',
  NCCI_INJURY_TYPE = 'NCCI_INJURY_TYPE',
  CLAIMANT = 'CLAIMANT',
  CLAIM_CREATED_DATE = 'CLAIM_CREATED_DATE',
  DATE_CLOSED = 'DATE_CLOSED',
  REOPENED_DATE = 'REOPENED_DATE',
  TOTAL_DAYS_LOST = 'TOTAL_DAYS_LOST',
  MEDICAL_PAID_COIN = 'MEDICAL_PAID_COIN',
  INDEMNITY_TOTAL_PAID_COIN = 'INDEMNITY_TOTAL_PAID_COIN',
  INDEMNITY_TOTAL_INCURRED_COIN = 'INDEMNITY_TOTAL_INCURRED_COIN',
  EXPENSE_PAID_COIN = 'EXPENSE_PAID_COIN',
  EXPENSE_TOTAL_INCURRED_COIN = 'EXPENSE_TOTAL_INCURRED_COIN',
  TOTAL_PAID_COIN = 'TOTAL_PAID_COIN',
  TOTAL_INCURRED_COIN = 'TOTAL_INCURRED_COIN',
  DATE_OF_DEATH = 'DATE_OF_DEATH',
  LOSS_PAID_COIN = 'LOSS_PAID_COIN',
  LOSS_RESERVES_COIN = 'LOSS_RESERVES_COIN',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  LOCATIONADDRESSLINE1 = 'LOCATION_ADDRESS_LINE1',
  LOCATION_CITY = 'LOCATION_CITY',
  LOCATION_COUNTY = 'LOCATION_COUNTY',
  LOCATION2 = 'LOCATION2',
  LOCATION2NUMBER = 'LOCATION2_NUMBER',
  LOCATION2ADDRESSLINE1 = 'LOCATION2_ADDRESS_LINE1',
  LOCATION2CITY = 'LOCATION2_CITY',
  LOCATION2STATE = 'LOCATION2_STATE',
  LOCATION2ZIP = 'LOCATION2_ZIP',
  DATE_OFHIRE = 'DATE_OFHIRE',
  CLAIM_SUBMITTED_LAG = 'CLAIM_SUBMITTED_LAG',
  DATE_OF_LOSS = 'DATE_OF_LOSS',
  MEDICAL_RESERVES_COIN = 'MEDICAL_RESERVES_COIN',
  INDEMNITY_TOTAL_RESERVES_COIN = 'INDEMNITY_TOTAL_RESERVES_COIN',
  EXPENSE_RESERVES_COIN = 'EXPENSE_RESERVES_COIN',
  OUTSTANDING_RESERVES_COIN = 'OUTSTANDING_RESERVES_COIN',
  MEDICAL_TOTAL_INCURRED_COIN = 'MEDICAL_TOTAL_INCURRED_COIN',
  ADJUSTER = 'ADJUSTER',
  ADJUSTER_EMAIL = 'ADJUSTER_EMAIL',
  ADJUSTER_PHONE = 'ADJUSTER_PHONE',
  RETURNED_TO_WORK = 'RETURNED_TO_WORK',
  RISKLOWERINGFACTOR1 = 'RISK_LOWERING_FACTOR1',
  RISKLOWERINGFACTOR2 = 'RISK_LOWERING_FACTOR2',
  RISKLOWERINGFACTOR3 = 'RISK_LOWERING_FACTOR3',
  RISKLOWERINGFACTOR4 = 'RISK_LOWERING_FACTOR4',
  RISKLOWERINGFACTOR5 = 'RISK_LOWERING_FACTOR5',
  RISKRAISINGFACTOR1 = 'RISK_RAISING_FACTOR1',
  RISKRAISINGFACTOR2 = 'RISK_RAISING_FACTOR2',
  RISKRAISINGFACTOR3 = 'RISK_RAISING_FACTOR3',
  RISKRAISINGFACTOR4 = 'RISK_RAISING_FACTOR4',
  TOTAL_INCURRED_PREDICTION_COIN = 'TOTAL_INCURRED_PREDICTION_COIN',
  NET_INCURRED_COIN = 'NET_INCURRED_COIN',
  OUTSTANDING_RESERVES_LAST_CLOSURE_COIN = 'OUTSTANDING_RESERVES_LAST_CLOSURE_COIN',
  DATE_SUIT_FILED = 'DATE_SUIT_FILED',
  SUIT_FILED = 'SUIT_FILED',
  DATE_OF_MAX_IMPROVEMENT = 'DATE_OF_MAX_IMPROVEMENT',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  COPD_FLAG = 'COPD_FLAG',
  CORONARY_ARTERY_DISEASE_FLAG = 'CORONARY_ARTERY_DISEASE_FLAG',
  OSTEOARTHRITIS_FLAG = 'OSTEOARTHRITIS_FLAG',
  OTHER_CARDIAC_DISORDERS_FLAG = 'OTHER_CARDIAC_DISORDERS_FLAG',
  CARRIER = 'CARRIER',
  SUBSTANCE_DEPENDENCE_FLAG = 'SUBSTANCE_DEPENDENCE_FLAG',
  CLAIMANT_EMAIL = 'CLAIMANT_EMAIL',
  CLAIMANT_PHONE = 'CLAIMANT_PHONE',
  CLAIMANT_WORK_EMAIL = 'CLAIMANT_WORK_EMAIL',
  CLAIMANT_WORK_PHONE = 'CLAIMANT_WORK_PHONE',
  CLAIMANT_STATE = 'CLAIMANT_STATE',
  CLAIMANT_CITY = 'CLAIMANT_CITY',
  MOBILE_PHONE = 'MOBILE_PHONE',
  HANDLING_OFFICE = 'HANDLING_OFFICE',
  CLAIM_DENIED = 'CLAIM_DENIED',
  SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
  JOB_TITLE_CARRIER = 'JOB_TITLE_CARRIER',
  SUPERVISOR = 'SUPERVISOR',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  CLAIMANT_ATTORNEY_REPRESENTED = 'CLAIMANT_ATTORNEY_REPRESENTED',
  GROUPCODE1 = 'GROUP_CODE1',
  CLAIM_SUBMITTED_USERID = 'CLAIM_SUBMITTED_USERID',
  CLAIMANT_DIED = 'CLAIMANT_DIED',
  INITIAL_TREATMENT = 'INITIAL_TREATMENT',
  PHYSICIAN_HOSPITAL_NAME = 'PHYSICIAN_HOSPITAL_NAME',
  PERCENT_IMPAIRMENT = 'PERCENT_IMPAIRMENT',
  TOTAL_REIMBURSEMENT_COIN = 'TOTAL_REIMBURSEMENT_COIN',
  TTD_RATE = 'TTD_RATE',
  WEEKLY_WAGE = 'WEEKLY_WAGE',
  PPD_RATE = 'PPD_RATE',
  ELECTRO_DIAGNOSTIC_PREDICTION = 'ELECTRO_DIAGNOSTIC_PREDICTION',
  IMAGING_PREDICTION = 'IMAGING_PREDICTION',
  OPIOID_PREDICTION = 'OPIOID_PREDICTION',
  PAIN_MANAGEMENT_PREDICTION = 'PAIN_MANAGEMENT_PREDICTION',
  PHYS_THERAPY_CHIRO_PREDICTION = 'PHYS_THERAPY_CHIRO_PREDICTION',
  SURGERY_PREDICTION = 'SURGERY_PREDICTION',
  SETTLEMENT_DATE = 'SETTLEMENT_DATE',
  CLAIMANT_ZIP = 'CLAIMANT_ZIP',
  TERMINATION_DATE = 'TERMINATION_DATE',
  SIR_DEDUCTIBLE_COIN = 'SIR_DEDUCTIBLE_COIN',
  SETTLEMENT_AMOUNT_COIN = 'SETTLEMENT_AMOUNT_COIN',
  SUBROGATION_ESTIMATED_AMOUNT_COIN = 'SUBROGATION_ESTIMATED_AMOUNT_COIN',
  CLAIMANT_AGE = 'CLAIMANT_AGE',
  PREDICTION_DATE = 'PREDICTION_DATE',
  POLICY_PERIOD_END_DATE = 'POLICY_PERIOD_END_DATE',
  POLICY_PERIOD_START_DATE = 'POLICY_PERIOD_START_DATE',
  CLAIMANT_REPORT_DATE = 'CLAIMANT_REPORT_DATE',
  CLAIM_DENIED_DATE = 'CLAIM_DENIED_DATE',
  CLAIM_SUBMITTED_DATE = 'CLAIM_SUBMITTED_DATE',
  DATE_LAST_WORKED = 'DATE_LAST_WORKED',
  FIRST_DAY_OF_DISABILITY = 'FIRST_DAY_OF_DISABILITY',
  FULL_DUTY_DATE = 'FULL_DUTY_DATE',
  RETURN_TO_WORK_DATE = 'RETURN_TO_WORK_DATE',
  LAST_PAYMENT_DATE = 'LAST_PAYMENT_DATE',
}

export interface FieldFilter {
  fieldType: EFieldType;
  claimNumber?: string[];
  claimant?: string[];
  nameInsured?: string[];
}

export interface FieldOutDto {
  fieldValue: string;
  /** @format int64 */
  matches?: number;
}

export interface PageResponseFieldOutDto {
  result: FieldOutDto[];
  /** @format int64 */
  total: number;
}

export interface CalcAwsInDto {
  /** @example "Company 1" */
  companyName?: string;
  /**
   * @format int32
   * @example 50
   */
  targetLossRatio?: number;
  /**
   * @format int32
   * @example 5
   */
  yearsInBusiness?: number;
  /**
   * @format double
   * @example 0.3
   */
  emr: number;
  mostRecent: ClaimsDto;
  previous?: ClaimsDto;
  twoYearsPrevious?: ClaimsDto;
  stateInputs: StateInput[];
}

export interface ClaimsDto {
  /**
   * @format int32
   * @example 1
   */
  medicalClaims?: number;
  /**
   * @format int32
   * @example 5
   */
  indemnityClaims?: number;
  /**
   * @format double
   * @example 0.5
   */
  totalMedicalLosses?: number;
  /**
   * @format double
   * @example 0.78
   */
  totalIndemnityLosses?: number;
}

export interface AssessmentClaimDto {
  trendOver?: string;
  trendExpected?: ETrendExpected;
}

export interface AssessmentSummaryDto {
  medicalClaims?: AssessmentClaimDto;
  indemnityClaims?: AssessmentClaimDto;
  avgMedicalLossPerIndicent?: AssessmentClaimDto;
  avgIndemnityLossPerIncident?: AssessmentClaimDto;
}

export interface CalcAwsDataDto {
  companyName?: string;
  confidence?: string;
  premium?: string;
  /** @format double */
  targetLossRatio?: number;
  /** @format double */
  sti?: number;
  /** @format double */
  coEmr?: number;
  /** @format double */
  expectedEmr?: number;
  expectedDa?: string;
  /** @format double */
  assocDues?: number;
  /** @format double */
  coPayroll?: number;
  /** @format double */
  coTrir?: number;
  /** @format double */
  coRt?: number;
  /** @format double */
  coDa?: number;
  /** @format double */
  expectedTrir?: number;
  /** @format double */
  expectedRt?: number;
  claimsSummary?: ClaimsSummary;
  stateOutputs?: StateSummary[];
  assessmentSummary?: AssessmentSummaryDto;
  comparisons?: Comparisons;
  /** @format double */
  safetierPremium?: number;
  /** @format double */
  finalPremium?: number;
  /** @format double */
  totalAdjExpectedClaim?: number;
}

export interface CalcAwsOutDto {
  isSuccessful: boolean;
  /** @format int64 */
  responseId?: number;
  responseType: EOutcomeType;
  version?: StiResponseVersion;
  warnings?: StiResponseDetailErrors[];
  errors?: StiResponseDetailErrors[];
  payload: JsonNode;
  data?: CalcAwsDataDto;
}

export interface ClaimsResponse {
  /** @format double */
  actualClaims?: number;
  /** @format double */
  expectedClaims?: number;
  /** @format double */
  actualLossess?: number;
  /** @format double */
  expectedLosses?: number;
}

export interface ClaimsSummary {
  medical?: ClaimsResponse;
  indemnity?: ClaimsResponse;
  totals?: ClaimsResponse;
}

export enum ETrendExpected {
  STEADILY_INCREASING = 'STEADILY_INCREASING',
  STEADILY_DECREASING = 'STEADILY_DECREASING',
  FLAT = 'FLAT',
  MIXED = 'MIXED',
  MIXED_BUT_INCREASING = 'MIXED_BUT_INCREASING',
  OTHER = 'OTHER',
}

export interface StateSummary {
  stateCode?: string;
  ncciCode?: string;
  payroll?: string;
  finalPremium?: string;
  finalCrDb?: string;
  filingType?: string;
  lc?: string;
  safetierPremium?: string;
  adjExpectedClaim?: string;
  stiMult?: string;
  netRate?: string;
  lcm?: string;
}

export interface UserStatusInDto {
  userStatus: EUserStatus;
}

export interface SubmissionPatchDto {
  assignee?: string;
  verified?: boolean;
}

export interface UserStatusOutDto {
  statuses: EUserStatus[];
}

export interface UserConfirmationStatusOutDto {
  statuses: EUserConfirmationStatus[];
}

export interface PageResponseWCDocumentOutDto {
  result: WCDocumentOutDto[];
  /** @format int64 */
  total: number;
}

export interface WCDocumentOutDto {
  title: string;
  name: string;
  titleShort: string;
  description: string;
  required: boolean;
  link: string;
}

export type ClaimsBillingLocationOutDto = {
  locationStreet?: string | null;
  locationState?: string | null;
  locationCity?: string | null;
  locationZip?: string | null;
  email?: string | null;
  phone?: string | null;
};

export type ClaimsHandlingLocationOutDto = {
  handlingLocation?: string | null;
  locationStreet?: string | null;
  locationState?: string | null;
  locationCity?: string | null;
  locationZip?: string | null;
  phone?: string | null;
};

export interface WCStateAdditionalInfoOutDto {
  claimsHandlingLocation?: ClaimsHandlingLocationOutDto;
  claimsBillingLocation?: ClaimsBillingLocationOutDto;
}

export interface PageResponseWCComplianceOutDto {
  result: WCComplianceOutDto[];
  /** @format int64 */
  total: number;
}

export interface WCComplianceOutDto {
  title: string;
  content: string;
}

export interface WCStateOutDto {
  stateCode: string;
  stateName: string;
  enabled: boolean;
}

export interface UsersOutDto {
  users: UserOutDto[];
  last: boolean;
}

export interface UserOutCompactDto {
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
}

export interface UserPoolGroupOutDto {
  groupList: string[];
}

export interface PageResponseSubmissionOutDto {
  result: SubmissionOutDto[];
  /** @format int64 */
  total: number;
}

export interface SubmissionFileOutDto {
  /** @format int64 */
  id: number;
  filename: string;
  status: ESubmissionFileStatus;
  errorMessage?: string | null;
  resultFiles?: (string | null)[];
  /** @format date-time */
  createdDate: string;
}

export interface CalcOutDto {
  isSuccessful: boolean;
  /** @format int64 */
  responseId?: number;
  responseType: EOutcomeType;
  version?: StiResponseVersion;
  warnings?: StiResponseDetailErrors[];
  errors?: StiResponseDetailErrors[];
  payload: JsonNode;
}

export enum ESubmissionClaimStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum ESubmissionClaimType {
  INDEMNITY = 'INDEMNITY',
  MEDICAL = 'MEDICAL',
  REPORT_ONLY = 'REPORT_ONLY',
}

export interface SubmissionLossClaimOutDto {
  /** @format date */
  dateOfLoss: string;
  status: ESubmissionClaimStatus;
  type: ESubmissionClaimType;
  /** @format double */
  medical?: number | null;
  /** @format double */
  indemnity?: number | null;
  /** @format double */
  expense?: number | null;
  /** @format double */
  reserves?: number | null;
  /** @format double */
  incurred?: number | null;
  details?: string | null;
}

export interface SubmissionLossSummaryOutDto {
  /** @format double */
  trir?: number | null;
  /** @format double */
  dart?: number | null;
  total: SubmissionLossSummaryTotalOutDto;
  years: SubmissionLossSummaryYearsOutDto[];
}

export interface SubmissionLossSummaryTotalOutDto {
  totalYears: SubmissionLossSummaryTotalYearOutDto[];
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
  /** @format int32 */
  numberOfClaims: number;
  /** @format int32 */
  totalMedicalClaimsOpenNum: number;
  /** @format double */
  totalMedicalClaimsOpenValue: number;
  /** @format int32 */
  totalMedicalClaimsClosedNum: number;
  /** @format double */
  totalMedicalClaimsClosedValue: number;
  /** @format int32 */
  totalIndemnityClaimsOpenNum: number;
  /** @format double */
  totalIndemnityClaimsOpenValue: number;
  /** @format int32 */
  totalIndemnityClaimsClosedNum: number;
  /** @format double */
  totalIndemnityClaimsClosedValue: number;
  /** @format double */
  totalExpensesIncurred: number;
  /** @format int32 */
  totalReportOnlyClaimsOpenNum: number;
  /** @format int32 */
  totalReportOnlyClaimsClosedNum: number;
}

export interface SubmissionLossSummaryTotalYearOutDto {
  /** @format date */
  policyTermStart: string;
  /** @format date */
  policyTermEnd: string;
  /** @format int32 */
  medicalClaimsOpenNum: number;
  /** @format double */
  medicalClaimsOpenValue: number;
  /** @format int32 */
  medicalClaimsClosedNum: number;
  /** @format double */
  medicalClaimsClosedValue: number;
  /** @format int32 */
  indemnityClaimsOpenNum: number;
  /** @format double */
  indemnityClaimsOpenValue: number;
  /** @format int32 */
  indemnityClaimsClosedNum: number;
  /** @format double */
  indemnityClaimsClosedValue: number;
  /** @format double */
  expensesIncurred: number;
  /** @format int32 */
  reportOnlyClaimsOpenNum: number;
  /** @format int32 */
  reportOnlyClaimsClosedNum: number;
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
  /** @format int32 */
  numberOfClaims: number;
}

export interface SubmissionLossSummaryYearsOutDto {
  /** @format date */
  effectiveDateStart: string;
  /** @format date */
  effectiveDateEnd: string;
  claims: SubmissionLossClaimOutDto[];
  /** @format int32 */
  claimCount: number;
  /** @format double */
  totalMedical: number;
  /** @format double */
  totalIndemnity: number;
  /** @format double */
  totalExpense: number;
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
}

export interface SubmissionLossReviewCarrierOutDto {
  /** @format date */
  effectiveDateStart: string;
  /** @format date */
  effectiveDateEnd: string;
  claims: SubmissionLossClaimOutDto[];
  /** @format int32 */
  claimCount: number;
  /** @format double */
  totalMedical: number;
  /** @format double */
  totalIndemnity: number;
  /** @format double */
  totalExpense: number;
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
  name: string;
}

export interface SubmissionLossReviewOutDto {
  total: SubmissionLossReviewTotalOutDto;
  carriers: SubmissionLossReviewCarrierOutDto[];
}

export interface SubmissionLossReviewTotalCarrierOutDto {
  name: string;
  /** @format int32 */
  coverageStartYear: number;
  /** @format int32 */
  coverageEndYear: number;
  /** @format double */
  totalExpense: number;
  /** @format double */
  totalMedical: number;
  /** @format double */
  totalIndemnity: number;
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
  /** @format int32 */
  numberOfClaims: number;
}

export interface SubmissionLossReviewTotalOutDto {
  submissionTotalCarriers: SubmissionLossReviewTotalCarrierOutDto[];
  /** @format double */
  totalExpense: number;
  /** @format double */
  totalMedical: number;
  /** @format double */
  totalIndemnity: number;
  /** @format double */
  totalReserves: number;
  /** @format double */
  totalIncurred: number;
  /** @format int32 */
  numberOfClaims: number;
}

export interface PresignedUrlOutDto {
  downloadUrl: string;
}

export interface PageResponseSubmissionNamedInsuredOutDto {
  result: SubmissionNamedInsuredOutDto[];
  /** @format int64 */
  total: number;
}

export interface StateOutDto {
  /** @format int64 */
  id: number;
  title: string;
  code: string;
}

export interface UserPoolNameOutDto {
  userPoolNameList: string[];
}

export enum EStiRequestType {
  FULL = 'FULL',
  AWS = 'AWS',
}

export interface NcciOutDto {
  /** @format int64 */
  id: number;
  ncci: string;
  classificationWording: string;
}

export interface PageResponseNcciOutDto {
  result: NcciOutDto[];
  /** @format int64 */
  total: number;
}

export interface NaicsOutDto {
  /** @format int64 */
  id: number;
  description: string;
  naics: string;
}

export interface PageResponseNaicsOutDto {
  result: NaicsOutDto[];
  /** @format int64 */
  total: number;
}

export interface CalculateLossRatio {
  namedInsured: string;
  /** @format int32 */
  lossRatioPercent: number;
}

export interface CalculateLossRatioDTO {
  result: CalculateLossRatio[];
}

export interface FilterValueOutDto {
  filterValue: string;
  active: boolean;
  description?: string;
}

export interface VersionOutDto {
  responseMessage: string;
  /** @format int32 */
  responseCode?: number;
  versions: string[];
}

export interface EmailAttachmentReceivedOutDto {
  /** @format int64 */
  id: number;
  /** @format int64 */
  emailReceivedId: number;
  filename: string;
  fileType: string;
  /** @format date-time */
  dateCreated: string;
  /** @format date-time */
  dateModified?: string;
}

export interface PageResponseEmailAttachmentReceivedOutDto {
  result: EmailAttachmentReceivedOutDto[];
  /** @format int64 */
  total: number;
}
