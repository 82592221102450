import { useState, useMemo, useCallback, ClassAttributes, HTMLAttributes } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import { typedEnv } from '@app/environment/typed-env';

import { DeveloperMode } from '@mui/icons-material';

import { Button } from '@app/components';
import { DevTool } from '@hookform/devtools';

type HTMLProps<T> = ClassAttributes<T> & HTMLAttributes<T>;

interface Props<T extends FieldValues> extends Omit<HTMLProps<HTMLFormElement>, 'className'> {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  control: Control<T>;
}

export function Form<T extends FieldValues>({
  children,
  control,
  className,
  onSubmit,
  ...rest
}: React.PropsWithChildren<Props<T>>) {
  const [showDevTools, setShowDevTools] = useState(false);

  const toggleDevTools = useCallback(() => {
    setShowDevTools(!showDevTools);
  }, [showDevTools]);

  const renderDevButton = useMemo(() => {
    if (typedEnv.NODE_ENV === 'development') {
      return (
        <>
          <Button
            onClick={toggleDevTools}
            color="primary"
            variant="outlined"
            className="absolute -top-6 right-0 min-w-0 p-1"
          >
            <DeveloperMode fontSize="inherit" />
          </Button>
          {showDevTools && <DevTool control={control} placement="top-right" />}
        </>
      );
    }
    return null;
  }, [control, showDevTools, toggleDevTools]);
  return (
    <>
      <form onSubmit={onSubmit} className={clsx('relative', className)} {...rest}>
        {children}
        {renderDevButton}
      </form>
    </>
  );
}
