import { FC } from 'react';
import AutoGraphOutlined from '@mui/icons-material/AutoGraphOutlined';
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined';
import SearchFilled from '@mui/icons-material/Search';
import TopicOutlined from '@mui/icons-material/TopicOutlined';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';
import PeopleFilled from '@mui/icons-material/People';

import { SidebarRouteGroups } from '@app/models/router';
import { NavSidebar } from '@app/layout/nav-sidebar/NavSidebar';
import { Routes } from '@app/constants/routes';
import { ERole } from '@app/auth-types';
import {
  HAS_ACCESS_TO_STI,
  HAS_ACCESS_TO_CLAIMS,
  HAS_ACCESS_TO_CLAIMS_INFO,
  HAS_ACCESS_TO_LOSS_RATIO,
  HAS_ACCESS_TO_UNDERWRITING,
  HAS_ACCESS_TO_ADMIN,
} from '@app/environment/typed-env';
import { GROUPS_WITH_ACCESS_TO_STI, GROUPS_WITH_ACCESS_TO_SUBMISSIONS } from '@app/domain/sti/const/sti-access.const';

const navItems: SidebarRouteGroups[] = [
  {
    groupName: 'Underwriting',
    routes: [
      {
        path: Routes.sti.index,
        name: 'SafeTier Rating',
        icon: <AutoGraphOutlined />,
        role: GROUPS_WITH_ACCESS_TO_STI,
        disabled: !HAS_ACCESS_TO_STI,
      },
      {
        path: Routes.underwriting.submissions,
        name: 'Submissions',
        icon: <EditNoteOutlined />,
        disabled: !HAS_ACCESS_TO_UNDERWRITING,
        role: GROUPS_WITH_ACCESS_TO_SUBMISSIONS,
      },
    ],
  },
  {
    groupName: 'Claims',
    routes: [
      {
        path: Routes.claims.search,
        name: 'Search Claims',
        icon: <SearchFilled />,
        disabled: !HAS_ACCESS_TO_CLAIMS,
      },
      {
        path: Routes.claims.info,
        name: 'File a Claim',
        icon: <TopicOutlined />,
        disabled: !HAS_ACCESS_TO_CLAIMS_INFO,
      },
    ],
  },
  {
    groupName: 'Admin',
    routes: [
      {
        path: Routes.lossRatio.index,
        name: 'Loss Ratio',
        icon: <InsertChartOutlined />,
        // hide for prod
        disabled: !HAS_ACCESS_TO_LOSS_RATIO,
      },
      {
        path: Routes.admin.users,
        name: 'User Management',
        icon: <PeopleFilled />,
        role: [ERole.ALL_ADMINISTRATOR, ERole.INSURATE_USER_ADMIN, ERole.USISA_USER_ADMIN],
        disabled: !HAS_ACCESS_TO_ADMIN,
      },
    ],
  },
];

interface Props {
  open: boolean;
  badgesData?: Record<string, number>;
}

export const AdminSidebarMenu: FC<Props> = ({ badgesData, open }) => {
  return <NavSidebar navItems={navItems} badgesData={badgesData} open={open} />;
};
