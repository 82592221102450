import clsx from 'clsx';
import { format, getYear, subYears } from 'date-fns';
import { capitalize, range } from 'lodash';
import { forwardRef, useState } from 'react';

import { Popover, PopoverOrigin } from '@mui/material';

import { IconButton, Input, InputProps } from '@app/components';
import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';

import { DatePicker, DatePickerProps } from '../date-picker/DatePicker';

import { DatePickerIcon } from '@app/assets';
import { FORMAT } from '@app/constants/formats';
import { DateInput } from '../month-picker-range/DateInput';
import styles from './DatePickerInput.module.scss';

type RenderInputProps = {
  value: string;
  handleFocus(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
};

export type DatePickerInputProps = DatePickerProps & {
  inputProps?: InputProps;
  position?: 'left' | 'center' | 'right';
  formatDate?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  renderInput?(props: RenderInputProps): React.ReactNode;
  disableOpenOnFocus?: boolean;
  formattedTextInput?: boolean;
};

const stylesTyped: Record<string, any> = styles;

export const dobYears = range(getYear(new Date()) - 100, getYear(subYears(new Date(), 17)), 1).reverse();

export const standardYearsRange = range(getYear(new Date()) - 100, getYear(new Date()) + 1, 1).reverse();

export const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
  (
    {
      inputProps,
      color = 'white',
      selected,
      shouldCloseOnSelect,
      formatDate = FORMAT.DATE_YEAR,
      position = 'center',
      anchorOrigin,
      transformOrigin,
      onChange,
      renderInput,
      disabled,
      disableOpenOnFocus,
      formattedTextInput,
      ...rest
    },
    ref
  ) => {
    const [_value, handleDateChange] = useUncontrolled({
      value: selected,
      rule: (val) => typeof val === 'object',
      onChange,
    });
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [anchorProps] = useState<{
      anchorOrigin: PopoverOrigin;
      transformOrigin: PopoverOrigin;
    }>({
      anchorOrigin: anchorOrigin ?? {
        vertical: 'bottom',
        horizontal: position,
      },
      transformOrigin: transformOrigin ?? {
        vertical: 'top',
        horizontal: position,
      },
    });

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (date: Date | null) => {
      handleDateChange(date);
      if (shouldCloseOnSelect) {
        handleClose();
      }
    };

    const handleFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled || disableOpenOnFocus) {
        return null;
      }
      setAnchorEl(event.currentTarget);
    };

    const handlePickerIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const parentInput = event.currentTarget?.parentNode?.parentNode as HTMLDivElement;

      if (parentInput) {
        setAnchorEl(parentInput);
      }
    };

    return (
      <>
        {renderInput ? (
          renderInput({ handleFocus, value: _value ? format(_value, formatDate) : '' })
        ) : formattedTextInput ? (
          <DateInput
            dateFormat={formatDate}
            dateValue={_value || null}
            onChangeData={handleChange}
            maxDate={rest.maxDate}
            endAdornment={
              <IconButton
                className={styles.DatePickerButton}
                color="primary"
                variant="shaded"
                size={inputProps?.inputSize}
                onClick={handlePickerIconClick}
              >
                <DatePickerIcon />
              </IconButton>
            }
            {...inputProps}
          />
        ) : (
          <Input
            ref={ref}
            onClick={handleFocus}
            value={_value ? format(_value, formatDate) : ''}
            endAdornment={
              <IconButton
                className={styles.DatePickerButton}
                color="primary"
                variant="shaded"
                size={inputProps?.inputSize}
                onClick={handlePickerIconClick}
              >
                <DatePickerIcon />
              </IconButton>
            }
            {...inputProps}
          />
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorProps.anchorOrigin}
          transformOrigin={anchorProps.transformOrigin}
          classes={{
            paper: clsx(styles.Paper, stylesTyped[capitalize(color)]),
          }}
        >
          <DatePicker selected={_value} onChange={handleChange} {...rest} />
        </Popover>
      </>
    );
  }
);
