import { clsxm } from '@app/styles/clsxm';
import { roadmapData } from './roadmap.data';
import { CalculatedTask, ETaskStatus } from './roadmap.type';
import { guardForLeafTask } from './roadmap.util';

const renderTaskStatus = (task: CalculatedTask) => {
  switch (task.status) {
    case ETaskStatus.DONE:
      return '✅';
    case ETaskStatus.IN_PROGRESS:
      return '⏳';
    case ETaskStatus.NOT_STARTED:
      return '🔵';
    default:
      return '🤷‍♂️';
  }
};

const renderTaskHours = (task: CalculatedTask) => {
  const { monoTodoHours, monoReadyHours } = task;
  switch (task.status) {
    case ETaskStatus.DONE:
      return `${monoReadyHours}h done`;
    case ETaskStatus.IN_PROGRESS:
      return `${monoReadyHours}h done / ${monoTodoHours}h left`;
    case ETaskStatus.NOT_STARTED:
      return `${monoTodoHours}h left`;
    default:
      return 'No estimate';
  }
};

export const RoadmapPage: React.FC = () => {
  const renderTaskRecursively = (task: CalculatedTask, key: number, level: number) => {
    const renderTaskLine = () => {
      const textHours = ` ${renderTaskHours(task)}`;
      const asNodeTask = task.type === 'node' ? task : undefined;
      const maybeCannotEstimateIcon = asNodeTask?.someLeafHasUnknownEstimate ? ' 🤷‍♂️' : '';
      const intermidTask = Boolean(!task.isRootTask && asNodeTask);
      return (
        <div className={clsxm('group')}>
          <span className={clsxm(intermidTask && ['opacity-60'])}>
            {renderTaskStatus(task)}
            {maybeCannotEstimateIcon}
          </span>
          <span className={clsxm(task.isRootTask ? 'font-bold' : intermidTask && ['text-zinc-400', 'italic'])}>
            {' '}
            {task.name}
          </span>
          {task.isRootTask && asNodeTask ? (
            <span className="ml-2 text-zinc-300">
              {textHours} ({asNodeTask.countOfLeafTasks} leaves)
            </span>
          ) : (
            <span className="ml-2 text-zinc-300 opacity-0 group-hover:opacity-100">{textHours}</span>
          )}
        </div>
      );
    };

    if (guardForLeafTask(task)) {
      return <div key={key}>{renderTaskLine()}</div>;
    }

    const hasSubtasks = task.subtasks.length > 0;
    return (
      <div key={key}>
        {renderTaskLine()}
        {hasSubtasks && (
          <div className="pl-6">{task.subtasks.map((t, key) => renderTaskRecursively(t, key, level + 1))}</div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-5xl p-8">
      <div className="pt-20">
        <div className="fixed left-10 top-10 z-10 rounded bg-zinc-300 p-4 pb-1 pt-1">
          <div>
            Total ready:
            <b className="ml-2">{roadmapData.monoReadyHours}h</b>
          </div>
          <div>
            Total estimate left:
            <b className="ml-2">{roadmapData.monoTodoHours}h</b>
          </div>
          <div>
            Count Of Leaf Tasks:
            <b className="ml-2">{roadmapData.countOfLeafTasks}</b>
          </div>
        </div>
        {roadmapData.checklist.map((t, key) => {
          return (
            <div key={key} className="mb-6 mt-6">
              {renderTaskRecursively(t, key, 0)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
