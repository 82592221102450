import styles from './NavSidebarLink.module.scss';
import clsx from 'clsx';
import React, { MouseEvent, useEffect, useState } from 'react';

import { SidebarRouteLink } from '@app/models/router/';

import { Badge, Collapse, ListItem, ListItemText, Popover } from '@mui/material';

import { ConditionalWrapper } from '@app/hoc/conditional-wrapper/ConditionalWrapper';
import { Tooltip } from '@app/components';
import { NavLink } from '@app/layout/nav-link/NavLink';
import { clsxm } from '@app/styles/clsxm';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { useHandler } from '@app/hooks/useHandler.hook';

type NavSidebarLinkProps = {
  name: string;
  icon?: JSX.Element;
  badge?: number;
  badgesData?: Record<string, number>;
  tooltip?: string;
  isChild?: boolean;
  nestedLinks?: Omit<SidebarRouteLink, 'icon'>[];
  to: string;
  isOpenSidebar: boolean;
  preventClick?: boolean;
  className?: string;
  wrapperClassName?: string;
};

export const NavSidebarLink: React.FC<NavSidebarLinkProps> = ({
  name,
  icon,
  badge,
  nestedLinks,
  badgesData,
  isChild,
  isOpenSidebar,
  tooltip,
  to,
  preventClick,
  className,
  wrapperClassName,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (preventClick) {
      e.preventDefault();
    }
    if (nestedLinks?.length && !isOpenSidebar) {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleToggleCollapsed = useHandler((e: MouseEvent) => {
    if (preventClick) {
      e.preventDefault();
    }
    if (nestedLinks?.length && isOpenSidebar) {
      if (window.location.href.includes(to)) {
        e.preventDefault();
      }
      setCollapsed((prevState) => !prevState);
    }
  });

  useEffect(() => {
    if (!isOpenSidebar) {
      setCollapsed(false);
    }
  }, [isOpenSidebar]);

  return (
    <div className={wrapperClassName}>
      <ListItem
        className={clsx(styles.SidebarLink, className, {
          [styles.SidebarLinkInner]: isChild,
          [styles.HasChild]: !!nestedLinks,
        })}
        component={NavLink}
        to={to}
        activeClassName={styles.Active}
        onClick={isOpenSidebar ? handleToggleCollapsed : handleClick}
        {...rest}
      >
        <ConditionalWrapper
          condition={!!tooltip || !isOpenSidebar}
          wrapper={(children) => (
            <Tooltip
              classes={{
                tooltip: styles.Tooltip,
              }}
              title={tooltip || name}
              variant="dark"
              disableInteractive
            >
              {children as React.ReactElement}
            </Tooltip>
          )}
        >
          <div className={styles.LinkContent}>
            {icon}
            <ListItemText className={clsxm(styles.SidebarLinkText, isOpenSidebar ? '' : 'hidden')}>{name}</ListItemText>

            {badge ? (
              <Badge
                className={clsx(styles.Badge, { [styles.BadgeOverflow]: badge > 99 })}
                badgeContent={badge}
                color="primary"
              />
            ) : null}
            {nestedLinks?.length && isOpenSidebar ? (
              <ExpandLessRoundedIcon
                className={clsxm(
                  'rotate-180 text-gray-500 transition-all duration-300',
                  collapsed && 'rotate-360',
                  !isOpenSidebar && 'opacity-0'
                )}
              />
            ) : null}
          </div>
        </ConditionalWrapper>
      </ListItem>
      <Collapse in={collapsed} timeout={300}>
        {nestedLinks?.map((nestedLink) => (
          <NavSidebarLink
            key={nestedLink.name}
            name={nestedLink.name}
            to={nestedLink.path}
            isChild
            badge={nestedLink.badgeProp && badgesData ? badgesData[nestedLink.badgeProp] : undefined}
            badgesData={badgesData}
            tooltip={nestedLink.tooltip}
            isOpenSidebar={isOpenSidebar}
          />
        ))}
      </Collapse>
      {Boolean(nestedLinks?.length) && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onBlur={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="flex flex-col gap-2 p-2.5 text-gray-800">
            {nestedLinks?.map((nestedLink) => (
              <NavSidebarLink
                key={nestedLink.name}
                name={nestedLink.name}
                to={nestedLink.path}
                badge={nestedLink.badgeProp && badgesData ? badgesData[nestedLink.badgeProp] : undefined}
                badgesData={badgesData}
                tooltip={nestedLink.tooltip}
                isOpenSidebar={true}
                className="p-0"
              />
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};
