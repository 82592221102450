export const FORMAT = {
  YEAR: 'yyyy',
  DATE_YEAR: 'MM/dd/yyyy',
  DATE_MONTH: 'MM/yyyy',
  DATE_FULL_MONTH: 'MMMM d, yyyy',
  BINDING_MONTH_RESPONSE: 'MMM-yyyy',
  DATE_API: 'yyyy-MM-dd',
  DATE_TIME: 'yyyy-MM-dd HH:mm',
  DATE_YEAR_TIME: 'MM/dd/yyyy HH:mm',
  HYPHENATED_DATE_TIME_FORMAT: 'MM/dd/yyyy - HH:mm',
} as const;
