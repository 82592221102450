import { format, parse, isMatch } from 'date-fns';
import { FC, useEffect, useState } from 'react';

import { convertLocalToUTCDate } from '@app/utils/date.utils';
import { Input, InputProps } from '../fields/input/Input';

interface Props extends InputProps {
  dateFormat: string;
  onChangeData: (data: Date) => void;
  dateValue: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  active?: boolean;
}

export const DateInput: FC<Props> = ({
  dateValue,
  dateFormat,
  onChangeData,
  active,
  contentClassName,
  onChange,
  error,
  errorMessage,
  onBlur,
  minDate,
  maxDate,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [dateError, setDateError] = useState('');

  const formattedDateValue = dateValue ? format(dateValue, dateFormat) : '';

  useEffect(() => {
    setValue(formattedDateValue);
    setDateError('');
  }, [formattedDateValue]);

  return (
    <Input
      {...props}
      value={value}
      error={Boolean(dateError) || error || Boolean(errorMessage)}
      errorMessage={dateError || errorMessage}
      onChange={(e) => {
        onChange?.(e);
        setValue(e.target.value);
        setDateError('');
      }}
      onBlur={(e) => {
        onBlur?.(e);

        if (value && formattedDateValue !== value) {
          if (isMatch(value, dateFormat) && value.length === dateFormat.length) {
            const newUtcDate = convertLocalToUTCDate(parse(value, dateFormat, new Date()));

            if (minDate && newUtcDate < minDate) {
              setDateError(`Min date is ${format(minDate, dateFormat)}`);
            } else if (maxDate && newUtcDate > maxDate) {
              setDateError(`Max date is ${format(maxDate, dateFormat)}`);
            } else {
              onChangeData(parse(value, dateFormat, new Date()));
              setDateError('');
            }
          } else {
            setDateError(`Invalid format. Use ${dateFormat.toUpperCase()} instead.`);
          }
        }
      }}
      contentClassName={active ? 'border-yellow-dark' : contentClassName}
    />
  );
};
