import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params';

import { ThemeWrapper, ResponsiveManager } from '@app/hoc';
import { RootLayout } from '@app/modules/RootLayout';
import { DialogRenderComponent } from '@app/services/dialog-manager';
import { DEFAULT_QUERY_STALE_TIME } from './constants/query-config.const';
import { RouteQueryParamsAdapter } from './RouteAdapter';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  },
});

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const App: FC = () => {
  return (
    <Router>
      <ThemeWrapper>
        <QueryParamProvider
          ReactRouterRoute={RouteQueryParamsAdapter as unknown as React.FunctionComponent}
          stringifyOptions={queryStringifyOptions}
        >
          <QueryClientProvider client={queryClient}>
            <ResponsiveManager>
              <DialogRenderComponent />
              <RootLayout />
            </ResponsiveManager>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </QueryParamProvider>
        <ToastContainer hideProgressBar closeButton={false} />
      </ThemeWrapper>
    </Router>
  );
};

export default App;
