import { FORMAT } from '@app/constants/formats';
import { format, utcToZonedTime } from 'date-fns-tz';

export function getCurrentDateCST(withPrefix?: boolean): string {
  const outputTimeZone = 'America/Chicago';

  const now: Date = new Date();
  const outputDateTime: Date = utcToZonedTime(now, outputTimeZone);
  const formattedDateTime = format(outputDateTime, 'MM/dd/yyyy');

  return withPrefix ? `${formattedDateTime} CST` : formattedDateTime;
}

export function getCurrentDateTimeCST(withPrefix?: boolean): string {
  const outputTimeZone = 'America/Chicago';

  const now: Date = new Date();
  const outputDateTime: Date = utcToZonedTime(now, outputTimeZone);
  const formattedDateTime = format(outputDateTime, 'MM/dd/yyyy HH:mm');

  return withPrefix ? `${formattedDateTime} CST` : formattedDateTime;
}

export const getMaxDate = (firstDate: Date, secondDate: Date) => {
  return firstDate > secondDate ? firstDate : secondDate;
};

/**
 * Converts API suitable date string into date string for date picker
 * preserving timezone info, so date picker renders proper date.
 * @param date string in format "YYYY-MM-DD" (DATE_API_FORMAT)
 * @returns string in format "2021-11-09T05:00:00.000Z"
 */
export const getPickerDate = (date: number | Date | string) => {
  const normalDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(format(normalDate, FORMAT.DATE_API));
};

export const convertUTCToLocalDate = (date: Date | string) => {
  const normalDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(normalDate.getUTCFullYear(), normalDate.getUTCMonth(), normalDate.getUTCDate());
};

export const convertLocalToUTCDate = (date: Date | string) => {
  const normalDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(Date.UTC(normalDate.getFullYear(), normalDate.getMonth(), normalDate.getDate()));
};
