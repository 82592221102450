import { PropsWithChildren, ReactNode, FC, useRef } from 'react';
import { Typography } from '@mui/material';

import { Crumb } from '@app/components/breadcrumbs/Breadcrumbs';
import { useDimensions } from '@app/hooks/useDimensions';
import { isString } from '@app/utils/assertion.utils';
import { Header } from '@app/layout/header/Header';
import { clsxm } from '@app/styles/clsxm';
import styles from './Page.module.scss';

interface Props {
  title?: ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  breadcrumbs?: Crumb[];
  disableOverflow?: boolean;
  whiteBg?: boolean;
  subHeaderClassName?: string;
  subHeaderContent?: ReactNode;
  withOverflow?: boolean;
  withHeader?: boolean;
}

export const Page: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  className,
  headerClassName,
  contentClassName,
  wrapperClassName,
  disableOverflow,
  titleClassName,
  breadcrumbs,
  whiteBg,
  subHeaderClassName,
  subHeaderContent,
  withHeader = true,
  withOverflow = true,
}) => {
  const ref = useRef(null);

  const { width } = useDimensions(ref);

  const horizontalPaddings = width > 1200 ? 'px-28' : 'px-10';

  return (
    <div
      className={clsxm(
        'relative flex h-full w-full flex-col bg-neutral-50',
        disableOverflow && styles['disable-overflow'],
        withOverflow && 'overflow-auto',
        whiteBg && 'bg-white',
        wrapperClassName
      )}
      ref={ref}
      id="page"
    >
      <div className={clsxm(styles['page-root'], className)}>
        {withHeader && (
          <div className="sticky top-0 z-[11]">
            <Header
              breadcrumbs={breadcrumbs}
              className={clsxm(whiteBg && 'bg-white', horizontalPaddings, headerClassName)}
            />
          </div>
        )}
        {subHeaderContent && (
          <div
            className={clsxm(
              styles['subheader-content'],
              whiteBg && 'bg-white',
              horizontalPaddings,
              subHeaderClassName
            )}
          >
            {subHeaderContent}
          </div>
        )}
        <div className={clsxm(styles['page-content'], whiteBg && 'bg-white', horizontalPaddings, contentClassName)}>
          {title ? (
            isString(title) ? (
              <Typography variant="h1" className={clsxm('text-5xl font-light', titleClassName)}>
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};
