import { IconButton, Tooltip } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Slider } from '@mui/material';
import { FC, memo, useCallback, useMemo } from 'react';
import { getColorByFinalCrDB } from '../utils/sti.utils';
import { isNumber } from 'lodash';

interface Props {
  value: number;
  onChange: (val: number) => void;
  onReset?: () => void;
  defaultValue?: number;
  isResetting?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
}

export const StiSlider: FC<Props> = memo(
  ({ defaultValue = 0, value, onChange, disabled, onReset, isResetting, min = -100, max = 100 }) => {
    const marks = useMemo(
      () => [
        {
          value: defaultValue,
          label: `${defaultValue}%`,
        },
      ],
      [defaultValue]
    );

    const componentsProps = useMemo(
      () => ({
        markLabel: {
          style: {
            color: getColorByFinalCrDB(defaultValue),
          },
        },
      }),
      [defaultValue]
    );

    const handleChange = useCallback(
      (_: Event, val: number | number[]) => {
        if (value !== val && isNumber(val)) {
          const decimalPart = String(value).split('.')[1] ?? 0;
          const newValue = Number(`${val.toFixed(0)}.${decimalPart}`);

          onChange(newValue);
        }
      },
      [value, onChange]
    );

    return (
      <div className="flex w-full items-center gap-6">
        <div
          className="flex min-w-[100px] items-center justify-center rounded-xl border border-gray-400 bg-white p-3 py-3 font-bold tabular-nums"
          style={{ color: getColorByFinalCrDB(value) }}
        >
          {value}%
        </div>
        <IconButton
          className={clsxm(
            'rounded border border-solid border-black p-0.5 text-black',
            'disabled:border-gray-500 disabled:text-gray-500'
          )}
          size="large"
          disabled={disabled || isResetting || value <= min}
          onClick={() => onChange(Number((value - 0.01).toFixed(2)))}
        >
          <RemoveIcon />
        </IconButton>
        <div className="flex w-full items-center">
          <Slider
            value={value}
            onChange={handleChange}
            aria-label="STI CR/DB"
            min={min}
            max={max}
            step={1}
            marks={marks}
            disabled={disabled || isResetting}
            componentsProps={componentsProps}
          />
        </div>
        <div className="flex items-center gap-2">
          <IconButton
            className={clsxm(
              'rounded border border-solid border-black p-0.5 text-black',
              'disabled:border-gray-500 disabled:text-gray-500'
            )}
            size="large"
            disabled={disabled || isResetting || value >= max}
            onClick={() => onChange(Number((value + 0.01).toFixed(2)))}
          >
            <AddIcon />
          </IconButton>
          <Tooltip title="Adjuster Reset: Resets to the last value used.">
            <IconButton
              onClick={() => onChange(defaultValue)}
              className={clsxm('p-0.5 text-black', ' disabled:text-gray-500')}
              disabled={disabled || isResetting}
              size="large"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          </Tooltip>
          {onReset ? (
            <Tooltip title="Full Reset: Resets to original Safe-Tier value.">
              <IconButton
                onClick={onReset}
                className={clsxm('p-0.5 text-black', ' disabled:text-gray-500')}
                disabled={disabled}
                loading={isResetting}
                size="large"
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    );
  }
);
