import { ZodError as ZodValidationError } from 'zod';
import { showErrorToast } from '@app/utils/toast.utils';
import { StiResponseDetailErrors } from '@app/swagger-types';
import { IGNORED_ERROR_MESSAGES } from './error-manager.const';

export enum EErrorName {
  APP_ERROR = 'AppError',
  API_ERROR = 'ApiError',
  API_VALIDATION_ERROR = 'ApiValidationError',
  API_FORBIDDEN_ERROR = 'ApiForbiddenError',
}

export type ErrorPayload = {
  message: string;
  field?: string;
};

export type ServerErrorData = {
  errors: ErrorPayload[];
  status: number;
  timestamp: string;
  responseDetail?: StiResponseDetailErrors[];
};

export type SerializedError = {
  errorType: EErrorName;
  errors: ErrorPayload[];
};

export abstract class CustomError extends Error {
  abstract errorType: string;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  abstract serializeError(): SerializedError;
}

export class AppError extends CustomError {
  errorType = EErrorName.APP_ERROR;
  constructor(public message: string) {
    super(message);
    Object.setPrototypeOf(this, AppError.prototype);
  }

  serializeError() {
    return {
      errorType: this.errorType,
      errors: [{ message: this.message }],
    };
  }
}

export class ApiError extends CustomError {
  errorType = EErrorName.API_ERROR;
  status = 500;
  timestamp = new Date().toISOString();
  errors: ErrorPayload[];
  responseDetail: StiResponseDetailErrors[];

  constructor(errorData?: ServerErrorData, message?: string) {
    super(message || 'Api Error');
    this.status = errorData?.status || 500;
    this.responseDetail = errorData?.responseDetail || [];
    this.errors = errorData?.errors || [{ message: message || 'Unexpected Api Error' }];
    this.timestamp = errorData?.timestamp || new Date().toISOString();
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  serializeError() {
    return { errorType: this.errorType, errors: this.errors };
  }
}

export class ApiValidationError extends ApiError {
  errorType = EErrorName.API_VALIDATION_ERROR;
  status = 400;
  constructor(errorData?: ServerErrorData, message?: string) {
    super(errorData, message || 'Validation Error');
    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export class ApiForbiddenError extends ApiError {
  errorType = EErrorName.API_FORBIDDEN_ERROR;
  status = 403;
  constructor(errorData?: ServerErrorData, message?: string) {
    super(errorData, message || 'Forbidden Error');
    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export const handleError = (e: Error) => {
  if (e instanceof CustomError) {
    return e.serializeError();
  }

  if (e instanceof ZodValidationError) {
    console.error(e.message);
    const error = new AppError(e.errors[0]?.message || e.message);
    return error.serializeError();
  }

  const unknownAppError = new AppError(e.message || 'Unknown Application Error');
  return unknownAppError.serializeError();
};

export const toastifyValidationError = (e: Error | SerializedError) => {
  if ('errorType' in e) {
    if (e instanceof ApiValidationError || e.errorType === EErrorName.API_VALIDATION_ERROR) {
      showErrorToast(e.errors[0].message);
    }
    if (e instanceof ApiError) {
      e.errors.forEach((err) => {
        if (!IGNORED_ERROR_MESSAGES.includes(err.message)) {
          showErrorToast(err.message);
        }
      });
    }
  }
};
