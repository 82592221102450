import { Box, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@app/components';

export const ButtonExamples = () => {
  return (
    <>
      <Typography variant="h4" className="py-4">
        Buttons (Outlined):
      </Typography>
      <Grid container>
        <Grid item xs>
          <Typography variant="h5" className="py-2">
            Primary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="outlined" color="primary" size="small">
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="primary" size="medium">
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="primary" size="large">
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="primary" startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="primary" disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="primary" loading>
              Loading button
            </Button>
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" className="py-2">
            Secondary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" size="small">
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" size="medium">
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" size="large">
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color="secondary" loading>
              Loading button
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h4" className="py-4">
        Buttons (Contained):
      </Typography>
      <Grid container>
        <Grid item xs>
          <Typography variant="h5" className="py-2">
            Primary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="contained" color="primary" size="small">
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="primary" size="medium">
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="primary" size="large">
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="primary" startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="primary" disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="primary" loading>
              Loading button
            </Button>
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" className="py-2">
            Secondary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="contained" color="secondary" size="small">
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="secondary" size="medium">
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="secondary" size="large">
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="secondary" startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="secondary" disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="contained" color="secondary" loading>
              Loading button
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
