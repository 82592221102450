import NumberFormat from 'react-number-format';
import { isNumber } from 'lodash';

export const convertCentToUnit = (cents: number) => Number((cents * 0.01).toFixed(2));
export const convertUnitToCent = (usd: number) => Number((usd * 100).toFixed());

/**
 * Safari-compatible solution
 */
export const numberWithCommas = (number: number | string, dec = 0, dsep?: string, tsep?: string) => {
  if (isNaN(+number) || number == null) {
    return '';
  }

  number = (typeof number === 'string' ? parseFloat(number) : number).toFixed(~~dec);
  tsep = typeof tsep == 'string' ? tsep : ',';

  const parts = number.split('.');
  const fnums = parts[0];
  const decimals = parts[1] ? (dsep || '.') + parts[1] : '';

  return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, `$1${tsep}`) + decimals;
};

export const formatDollars = (amount: number | string, fractions = 0) => {
  let num = +amount;
  if (Number.isNaN(num)) {
    num = 0;
  }
  return `$${numberWithCommas(num.toFixed(fractions), fractions)}`;
};

export const renderDollarAmount = (amount?: number | null, decimalScale?: number) => {
  return isNumber(amount) ? (
    <NumberFormat
      value={amount}
      displayType="text"
      prefix="$"
      thousandSeparator
      decimalScale={decimalScale ?? 2}
      fixedDecimalScale={isNumber(decimalScale)}
    />
  ) : (
    '-'
  );
};

export const renderDollarAmountString = (amount?: number | null) => {
  if (Number.isNaN(amount)) {
    amount = 0;
  }

  return amount ? `$${amount.toFixed(2)}` : '-';
};
