import { lazy } from 'react';
import { Routes } from '@app/constants/routes';

import { HAS_ACCESS_TO_CLAIMS, HAS_ACCESS_TO_CLAIMS_INFO } from '@app/environment/typed-env';
import { MyRouteObject } from '@app/models/router';
import { ClaimsModule } from './ClaimsModule';
import { TypedNavigate } from '@app/router';

const SearchClaimsPage = lazy(() => import('@app/modules/claims/pages/SearchClaimsPage'));
const DetailedClaimPage = lazy(() => import('@app/modules/claims/pages/DetailedClaimPage'));
const ClaimDetailsPdfPage = lazy(() => import('@app/modules/claims/pdf/ClaimDetailsPdfPage'));
const ClaimsInfoFirstFillPage = lazy(() => import('@app/modules/claims/pages/ClaimsInfoFirstFillPage'));
const ClaimsInfoStatePage = lazy(() => import('@app/modules/claims/pages/ClaimsInfoStatePage'));
const ClaimsInfoPage = lazy(() => import('@app/modules/claims/pages/ClaimsInfoPage'));

export const ClaimRoutes: MyRouteObject = {
  path: Routes.claims.index,
  element: <ClaimsModule />,
  disabled: !HAS_ACCESS_TO_CLAIMS_INFO && !HAS_ACCESS_TO_CLAIMS,
  children: [
    { path: Routes.claims.search, element: <SearchClaimsPage />, disabled: !HAS_ACCESS_TO_CLAIMS },
    { path: Routes.claims.detailedClaim, element: <DetailedClaimPage />, disabled: !HAS_ACCESS_TO_CLAIMS },
    { path: Routes.claims.detailedClaimPdf, element: <ClaimDetailsPdfPage />, disabled: !HAS_ACCESS_TO_CLAIMS },
    { path: Routes.claims.info, element: <ClaimsInfoPage />, disabled: !HAS_ACCESS_TO_CLAIMS_INFO },
    { path: Routes.claims.state, element: <ClaimsInfoStatePage />, disabled: !HAS_ACCESS_TO_CLAIMS_INFO },
    { path: Routes.claims.first_fill, element: <ClaimsInfoFirstFillPage />, disabled: !HAS_ACCESS_TO_CLAIMS_INFO },
    {
      index: true,
      path: '*',
      element: <TypedNavigate to={HAS_ACCESS_TO_CLAIMS ? Routes.claims.search : Routes.claims.info} />,
    },
  ],
};
