import { apiService, validateAndAssert } from '@app/services/api-service';
import {
  SignUpStepOneInDto,
  UpdateUserInDto,
  UserConfirmationStatusOutDto,
  UserOutCompactDto,
  UserOutDto,
  UserPoolGroupOutDto,
  UserPoolNameOutDto,
  UsersOutDto,
  UserStatusInDto,
  UserStatusOutDto,
} from '@app/swagger-types';
import { typedEnv } from '@app/environment/typed-env';
import { SilentParams } from '@app/services/api-service/api.service';
import {
  UserConfirmationStatusOutDtoSchema,
  UserOutDtoSchema,
  UserPoolGroupOutDtoSchema,
  UserPoolNameOutDtoSchema,
  UsersCompactOutDtoSchema,
  UsersOutDtoSchema,
  UserStatusOutDtoSchema,
} from '@app/domain/user/modules/user.schema';

export interface UserPoolQueryParams extends SilentParams {
  userPoolName: string;
}

export interface GetUserQueryParams extends UserPoolQueryParams {
  email: string;
}

export interface GetUsersQueryParams extends UserPoolQueryParams {
  isNext?: boolean;
  limit?: number;
  filterType?: string;
  filterValue?: string;
}

export abstract class UserApi {
  static async getUsers(params: GetUsersQueryParams) {
    return validateAndAssert(
      apiService.get<UsersOutDto>('/private/users', {
        params,
      }),
      UsersOutDtoSchema
    );
  }
  static async getCompactUsers() {
    return validateAndAssert(apiService.get<UserOutCompactDto[]>('/private/users/compact'), UsersCompactOutDtoSchema);
  }
  static async getUser({ email, userPoolName }: GetUserQueryParams) {
    return validateAndAssert(
      apiService.get<UserOutDto>(`/private/users/${email}`, {
        params: { userPoolName },
      }),
      UserOutDtoSchema
    );
  }
  static async getPools() {
    return validateAndAssert(apiService.get<UserPoolNameOutDto>('/private/pools'), UserPoolNameOutDtoSchema);
  }
  static async getUserGroups(userPoolName: string) {
    return validateAndAssert(
      apiService.get<UserPoolGroupOutDto>(`/private/user-groups?userPoolName=${userPoolName}`),
      UserPoolGroupOutDtoSchema
    );
  }
  static async getUserStatuses() {
    return validateAndAssert(apiService.get<UserStatusOutDto>(`/public/user-statuses`), UserStatusOutDtoSchema);
  }
  static async getUserConfirmStatuses() {
    return validateAndAssert(
      apiService.get<UserConfirmationStatusOutDto>(`/public/user-confirm-statuses`),
      UserConfirmationStatusOutDtoSchema
    );
  }
  static async createUser({ userPoolName, silent, ...Dto }: SignUpStepOneInDto & UserPoolQueryParams) {
    return apiService.post(`/private/users?userPoolName=${userPoolName}`, Dto, { params: { silent } });
  }
  static async editUser({
    userPoolName,
    email,
    silent,
    ...Dto
  }: UpdateUserInDto & UserPoolQueryParams & { email: string }) {
    return apiService.put<UserOutDto>(`/private/users/${email}?userPoolName=${userPoolName}`, Dto, {
      params: { silent },
    });
  }
  static async deleteUser({ userPoolName, email }: UserPoolQueryParams & { email: string }) {
    return apiService.delete(`/private/users/${email}?userPoolName=${userPoolName}`);
  }
  static async changeUserStatus({
    userPoolName,
    email,
    ...Dto
  }: UserStatusInDto & UserPoolQueryParams & { email: string }) {
    return apiService.patch(`/private/users/${email}/status?userPoolName=${userPoolName}`, Dto);
  }
  static async resendInvitation({ userPoolName, email }: UserPoolQueryParams & { email: string }) {
    return apiService.post(`/private/resend-invitation/${email}?userPoolName=${userPoolName}`);
  }
  static async resetPassword({ userPoolName, email }: UserPoolQueryParams & { email: string }) {
    return apiService.patch(`/private/users/${email}/reset-password?userPoolName=${userPoolName}`);
  }

  static createImgSrcByUserPool(poolName: string) {
    return `${typedEnv.REACT_APP_API_BASE_URL}/private/pools/logo-url/${poolName}`;
  }
}
