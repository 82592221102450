import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { DEFAULT_CLAIM_FILTER_VALUES } from '@app/domain/claim/const/claims.filter.const';
import { ClaimFilter } from '@app/swagger-types';
import { useHandler } from '@app/hooks/useHandler.hook';
import { createFiltersDto } from '@app/domain/claim/utils/claim.filter.utils';
import { useFilterIdQueryParam } from '@app/domain/claim/hooks/useFilterIdQueryParam';
import { useClaimFiltersSessionStorage } from '../hooks/useClaimFiltersSessionStorage';
import { useSharedClaimFiltersSessionStorage } from '../hooks/useSharedClaimFiltersSessionStorage';
import { usePolicyNumberQueryParam } from '../hooks/usePolicyNumberQueryParam';

export const ClaimFiltersContext = createContext({
  filters: DEFAULT_CLAIM_FILTER_VALUES,
  setFilters: (_: ClaimFilter) => {},
  sharedFilters: DEFAULT_CLAIM_FILTER_VALUES,
  setSharedFilters: (_: ClaimFilter) => {},
  filtersDto: {} as ClaimFilter,
  onChangeFilterParam: (key: keyof ClaimFilter, _: ClaimFilter[typeof key]) => {},
  onResetFilters: () => {},
  onSubmitFilterParams: (_: ClaimFilter) => {},
});

interface Props {
  children: ReactNode;
}

export const ClaimFiltersContextProvider: FC<Props> = ({ children }) => {
  const [filterIdParam, setFilterIdParam] = useFilterIdQueryParam();
  const [policyNumberParam, setPolicyNumberParam] = usePolicyNumberQueryParam();
  const [sharedFilters, setSharedFilters] = useSharedClaimFiltersSessionStorage();
  const [filters, setFilters] = useClaimFiltersSessionStorage();

  const visibleFilters: ClaimFilter = useMemo(
    () => (filterIdParam ? sharedFilters : filters),
    [filterIdParam, sharedFilters, filters]
  );

  useEffect(() => {
    if (policyNumberParam && !filterIdParam) {
      setFilters({
        ...DEFAULT_CLAIM_FILTER_VALUES,
        policyNumber: [policyNumberParam],
      });
      setPolicyNumberParam('');
    }
  }, [policyNumberParam, filterIdParam, setFilters, setPolicyNumberParam]);

  const filtersDto: ClaimFilter = useMemo(() => createFiltersDto(visibleFilters), [visibleFilters]);

  const removeFilterIdParam = useHandler(() => {
    setFilterIdParam(undefined, 'pushIn');
  });

  const onChangeFilterParam = useHandler((key: keyof ClaimFilter, value: ClaimFilter[typeof key]) => {
    setFilters({ ...visibleFilters, [key]: value });
    removeFilterIdParam();
  });

  const onResetFilters = useHandler(() => {
    setFilters(DEFAULT_CLAIM_FILTER_VALUES);
    removeFilterIdParam();
  });

  const onSubmitFilterParams = useHandler((values: ClaimFilter) => {
    setFilters(values);
    removeFilterIdParam();
  });

  const value = useMemo(
    () => ({
      filters: visibleFilters,
      sharedFilters,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
    }),
    [
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      visibleFilters,
      sharedFilters,
    ]
  );

  return <ClaimFiltersContext.Provider value={value}>{children}</ClaimFiltersContext.Provider>;
};

export const useClaimFiltersContext = () => useContext(ClaimFiltersContext);
