import { FC, memo } from 'react';
import { ReactComponent as UsisaLogoIconSvg } from './icons/usisa-logo-icon.svg';
import { ReactComponent as InsurateLogoIconSvg } from './icons/insurate-logo-icon.svg';
import { Link, Typography } from '@mui/material';
import { typedEnv } from '@app/environment/typed-env';

export const ExternalAppsNavigation: FC = memo(() => {
  return (
    <div className="flex gap-3 rounded bg-white p-4">
      <Link href={typedEnv.REACT_APP_USISA_LINK} className="group flex flex-col items-center px-2.5 py-2">
        <UsisaLogoIconSvg className="h-8 w-8" />
        <Typography className="text-[14px] text-gray-800 transition-colors group-hover:text-primary">USISA</Typography>
      </Link>
      <Link href={typedEnv.REACT_APP_INSURATE_LINK} className="group flex flex-col items-center px-2.5 py-2">
        <InsurateLogoIconSvg className="h-8 w-8" />
        <Typography className="text-xs text-gray-800 transition-colors group-hover:text-primary">Insurate</Typography>
      </Link>
    </div>
  );
});
