import { EGroupLossRatioBy, LossRatioChartOutDto } from '@app/swagger-types';
import { Typography } from '@mui/material';
import * as d3 from 'd3';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useWindowSize } from 'react-use';

import './LossRatiosBarChart.scss';
import { Routes } from '@app/constants/routes';
import { POLICY_NUMBER_PARAM_KEY } from '@app/domain/claim/hooks/usePolicyNumberQueryParam';
import { useLossRatioFiltersContext } from '../context/LossRatioFiltersContext';
import { parse } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { convertLocalToUTCDate } from '@app/utils/date.utils';
import { ELossRatioTab, useLossRationTabQueryParam } from '../hooks/useLossRationTabQueryParam';

interface BarChartProps {
  data: LossRatioChartOutDto[];
  errMsg?: string;
  isPdf?: boolean;
}

export const LossRatiosBarChart: React.FC<BarChartProps> = memo(({ data, errMsg, isPdf }) => {
  const chartRef = useRef<SVGSVGElement | null>(null);
  const { width: screenWidth } = useWindowSize();

  const [, setTab] = useLossRationTabQueryParam();

  const { filtersDto, onChangeFilterParam } = useLossRatioFiltersContext();

  const sortedData = useMemo(() => data.sort((a, b) => b.rowValue - a.rowValue), [data]);

  const width = isPdf ? 800 : Math.min(screenWidth - /* approx sidebar width */ 600, /* max width */ 1600);
  const height = isPdf && sortedData.length > 60 ? 800 : Math.max(20 * (sortedData.length || 1), 100);
  const paddingLeft = Math.max(...sortedData.map(({ rowName }) => rowName.length), 10) * 6;

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const svg = d3.select(chartRef.current);

    // Clear previous chart
    svg.selectAll('*').remove();

    // Set view box
    svg.attr('viewBox', [0, 0, width, height]).attr('style', 'max-width: 100%; height: auto; overflow: visible;');

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(sortedData, (d) => d.rowValue) || 100])
      .range([0, width]);

    const yScale = d3
      .scaleBand()
      .domain(sortedData.map((d) => d.rowName))
      .range([0, height])
      .padding(0.1);

    // Render empty state with axes and text
    if (sortedData.length === 0) {
      const xAxis = d3.axisBottom(xScale).scale(d3.scaleLinear().domain([0, 0]).range([0, width]));
      svg.append('g').attr('transform', `translate(0, ${height})`).call(xAxis);

      const yAxis = d3.axisLeft(yScale).scale(d3.scaleBand().domain([]).range([0, height]).padding(0.1));
      svg.append('g').call(yAxis);

      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('font-size', '16px')
        .style('fill', 'black')
        .text('No data available');

      return;
    } else {
      // Create bars
      svg
        .selectAll('rect')
        .data(sortedData)
        .enter()
        .append('rect')
        .attr('x', 0)
        .attr('y', (d) => (yScale(d.rowName) || 0) + 1) // Adjusted y position
        .attr('height', yScale.bandwidth() ?? 0)
        .transition()
        .duration(800)
        .attr('width', (d) => xScale(d.rowValue) ?? 0)
        .attr('fill', '#EBA600');

      // Create x-axis
      const xAxis = d3.axisBottom(xScale);
      svg.append('g').attr('transform', `translate(0, ${height})`).call(xAxis);

      // Create y-axis
      const yAxis = d3
        .axisLeft(yScale)
        // hide labels
        .tickFormat(() => '');

      svg.append('g').call(yAxis);

      // Add labels vertically

      svg
        .append('g')
        .attr('class', 'labels')
        .selectAll('.label')
        .data(sortedData)
        .enter()
        .append('text')
        .attr('class', 'label')
        .text((d) => d.rowName)
        .attr('x', -10)
        .attr('y', (d) => (yScale(d.rowName) || 0) + yScale.bandwidth() / 2)
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .style('font-size', '10px')
        // Handle on click
        .attr('class', 'link')
        .on('click', (event, d) => {
          if (filtersDto.groupBy === EGroupLossRatioBy.POLICY_NUMBER) {
            // Navigate to claims
            window.location.href = `${Routes.claims.search}?${POLICY_NUMBER_PARAM_KEY}=${d.rowName}`;
          } else {
            // Change tab
            setTab(ELossRatioTab.TABLE, 'replaceIn');

            // Change filter value
            if (filtersDto.groupBy === EGroupLossRatioBy.NCCI) {
              onChangeFilterParam('ncci', [d.rowName]);
            } else if (filtersDto.groupBy === EGroupLossRatioBy.AGENCY) {
              onChangeFilterParam('agencies', [d.rowName]);
            } else if (filtersDto.groupBy === EGroupLossRatioBy.STATE) {
              onChangeFilterParam('states', [d.rowName]);
            } else if (filtersDto.groupBy === EGroupLossRatioBy.BINDING_MONTH) {
              const parsedDate = convertLocalToUTCDate(
                parse(d.rowName, FORMAT.BINDING_MONTH_RESPONSE, new Date())
              ).toISOString();

              onChangeFilterParam('bindingMonthStart', parsedDate);
              onChangeFilterParam('bindingMonthEnd', parsedDate);
            }
          }
        });

      // Add percentage values horizontally
      svg
        .selectAll('.percentage')
        .data(sortedData)
        .enter()
        .append('text')
        .attr('class', 'percentage')
        .text((d) => `${d.rowValue}%`)
        .attr('x', (d) => (xScale(d.rowValue) || 0) + 5)
        .attr('y', (d) => (yScale(d.rowName) || 0) + yScale.bandwidth() / 2)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-size', '12px');
    }
  }, [sortedData, height, width, onChangeFilterParam, filtersDto.groupBy, setTab]);

  return (
    <div
      style={{
        padding: data.length ? `30px 50px 30px ${paddingLeft}px` : '30px 50px',
        paddingTop: isPdf ? '0' : undefined,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <svg ref={chartRef} width={width} height={height}>
        {/* SVG content will be rendered here */}
      </svg>
      {errMsg ? (
        <Typography align="center" className="mt-8 text-red-500">
          {errMsg}
        </Typography>
      ) : null}
    </div>
  );
});
