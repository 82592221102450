import styles from './OptionItem.module.scss';
import clsx from 'clsx';
import React from 'react';

import { isString } from '@app/utils/assertion.utils';

import { MenuItem, MenuItemProps, ListItemText, Typography } from '@mui/material';

type PropTypes<T> = {
  value: T;
  rightAdornment?: React.ReactNode;
  className?: string;
  transparent?: boolean;
} & Omit<MenuItemProps, 'button' | 'value'>;

function OptionItemComponent<T>(
  { value, className, rightAdornment, children, transparent = false, ...rest }: React.PropsWithChildren<PropTypes<T>>,
  ref: React.Ref<HTMLLIElement>
) {
  return (
    <MenuItem
      ref={ref}
      value={value as any}
      className={clsx(transparent ? styles.TransparentItem : styles.Item, className)}
      {...rest}
    >
      <ListItemText primary={children} primaryTypographyProps={{ noWrap: true, className: 'text-xs' }} />
      {isString(rightAdornment) ? <Typography>{rightAdornment}</Typography> : rightAdornment}
    </MenuItem>
  );
}

export const OptionItem = React.forwardRef(OptionItemComponent) as <T>(
  p: React.PropsWithChildren<PropTypes<T>> & { ref?: React.Ref<HTMLLIElement> }
) => React.ReactElement;
